<template>
  <div class="hhDurationInput">
    <div style="flex: 1;"></div>
    <label style="display: flex;"><q-input :value="durationHours" @input="hoursUpdated" type="text" dense hide-bottom-space aria-label="hours" input-class="text-right" style="width: 40px;" :disable="disabled" />&nbsp;hour{{ durationHours == 1 ? '' : 's' }} &nbsp;</label>
    <label style="display: flex;"><q-input :value="durationMinutes" @input="minutesUpdated" type="text" dense hide-bottom-space aria-label="minutes" input-class="text-right" style="width: 40px;" :disable="disabled" />&nbsp;minute{{ durationMinutes == 1 ? '' : 's' }}</label>
  </div>
</template>

<script>
export default {
  name: 'hh-duration-input',
  props: {
    value: { type: [Number, String], default: 0 },
    disabled: { type: Boolean, default: false }
  },

  data () {
    return {
      durationHours: 0,
      durationMinutes: 0
    }
  },

  watch: {
    value () {
      this.setDuration();
    }
  },

  methods: {
    setDuration () {
      let minutes = 0;
      if (typeof this.value === 'string') {
        const trimmedValue = this.value.trim();
        if (this.isIntegerString(trimmedValue)) {
          minutes = parseInt(trimmedValue);
        }
      }
      else if (typeof this.value === 'number') {
        minutes = Math.floor(this.value);
      }

      this.durationHours = Math.floor(minutes / 60);
      this.durationMinutes = minutes % 60;
    },

    hoursUpdated (h) {
      this.durationHours = h.trim();
      this.emitUpdate();
    },

    minutesUpdated (m) {
      this.durationMinutes = m.trim();
      this.emitUpdate();
    },

    isIntegerString (v) {
      return /^\d+$/.test(v);
    },

    emitUpdate () {
      if (!this.isIntegerString(this.durationHours) || !this.isIntegerString(this.durationMinutes)) {
        this.$emit('error', true);
        return;
      }

      this.$emit('input', (parseInt(this.durationHours) * 60) + parseInt(this.durationMinutes));
      this.$emit('error', false);
    }
  },

  created () {
    this.setDuration();
  }
}
</script>

<style lang="stylus">
.hhDurationInput
  text-transform: none
  font-size: 24px
  color: #999
  line-height: 30px
  font-weight: normal
  display: flex
</style>
