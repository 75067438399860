<template>
  <q-select
    emit-value map-options options-sanitize dense
    :dark="componentIsDark"
    v-model="selected"
    :options="options"
    @input="setActiveInstitution"
    :color="componentIsDark ? 'white' : 'black'"
    behavior="menu"
    class="hhSelectInstitution"
  />
</template>

<script>
export default {
  name: 'hh-select-institution',
  props: {
    forceDark: { type: Boolean, default: false }
  },
  data () {
    return {
      selected: this.$store.state.activeInstitution ? this.$store.state.activeInstitution.id : 0
    }
  },
  computed: {
    componentIsDark: function () {
      return this.forceDark || this.$store.getters.activeInstitutionColorBrightness < 210;
    },
    options: function () {
      var opts = [];
      var userInstitutions = this.$store.getters.userInstitutions;

      for (var i = 0; i < userInstitutions.length; i++) {
        var inst = userInstitutions[i];
        if (inst.valid) {
          opts.push({ label: inst.name, value: inst.id });
        }
      }

      return opts;
    }
  },
  methods: {
    setActiveInstitution: function (institutionID) {
      this.$store.dispatch('setActiveInstitution', institutionID);
    }
  }
}
</script>

<style lang="stylus">
.hhSelectInstitution
  padding: 6px 10px

.hhSelectInstitution span
  font-weight: bold
  font-size: 18px
  letter-spacing: normal

.hhSelectInstitution i
  padding-top: 5px

.hhSelectInstitution ::before
  display: none

.hhSelectInstitution ::after
  display: none
</style>
