import VueGtag from 'vue-gtag'

export default ({ app, router, Vue }) => {
  Vue.use(VueGtag, {
    config: {
      // Previously, we used a different id for google anayltics when debugging the software
      // id: process.env.DEV || process.env.DEBUGGING ? '_put_id_here_' : 'G-HVPX4B3RX6',
      id: 'G-HVPX4B3RX6'
    },

    // https://matteo-gabriele.gitbook.io/vue-gtag/v/master/auto-tracking
    pageTrackerTemplate (route) {
      // We remove any unique item ids, converting a path such as
      // /opportunity/1234/commitment into /opportunity/_id_/commitment
      var path = route.path.replace(/\/(\d+)($|\/)/g, '/_id_$2');

      return {
        page_title: route.name,
        page_path: path
      }
    }
  }, router);
}
