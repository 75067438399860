<template>
  <div class="hhInputField" :class="{ hasError: error || hasError ? true : false }">
    <div v-if="error" class="error">{{ error }}</div>
    <label class="hhInputFieldLabel" :style="{ color: labelColor }">{{ label }}</label>

    <hhDateTime v-if="isDateInput" v-model="val" :type="type" @input="setValue" :format="format"
      :defaultDate="defaultDateObject" :min="min" :max="max" :suffix="suffix" :disabled="isDisabled" />

    <q-select v-else-if="isSelectAutocomplete" :value="val" @input="setValue" @input-value="setValue"
      input-debounce="0" behavior="menu" new-value-mode="add"
      options-sanitize use-input hide-selected fill-input dense hide-dropdown-icon
      :options="autocompleteOptions" @filter="autocompleteFilter"
      @focus="setFocused" @blur="setBlurred" :disable="disabled" />

    <q-select v-else-if="isSelectInput" v-model="val" :options="options" @input="setValue" :disable="disabled"
      emit-value map-options options-sanitize dense style="z-index: 6020;" />

    <hhCheckboxGroup v-else-if="type == 'checkboxes'" v-model="val" :disabled="isDisabled" :options="options" @input="setValue" />

    <hhSelectMultiple v-else-if="isMultiSelectInput" v-model="val" :disabled="isDisabled" :options="options" :max="max" @input="setValue" />

    <hhFileUpload v-else-if="type == 'upload'" :files="files" :maxfiles="max" :disabled="isDisabled" :accept="format" :apiData="uploadAPIData" :nodelete="!canDeleteFile" :preview="isPreview" @input="setValue" />

    <hhDuration v-else-if="type == 'duration'" v-model="val" :disabled="isDisabled" @input="setValue" @error="setError" />

    <hhPhoneInput v-else-if="type == 'tel'" v-model="val" :defaultCountry="defaultPhoneCountry" :disabled="isDisabled" @input="setValue" @error="setError" />

    <!-- autogrow was causing a failure of rows="3" to be respected, so we added input-style in that scenario -->
    <q-input v-else v-model="val" :type="inputType" dense hide-bottom-space ref="inputField" @input="setValue" :autofocus="autofocus" @focus="setFocused" @blur="setBlurred" :placeholder="placeholder" :disable="isDisabled" :maxlength="max" @keyup.enter="$emit('enter')" :rows="type == 'textarea' ? 3 : 1" :color="color" :suffix="suffix" :autogrow="isAutogrow ? true : false" :input-style="{ minHeight: isAutogrow ? '84px' : '0px', fontSize: fontSize ? fontSize : '24px' }">
      <template v-if="type == 'password'" slot="append">
        <q-icon
          :name="showPassword ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          @click="showPassword = !showPassword"
        />
      </template>
      <template v-else-if="isSearchInput" slot="prepend">
        <q-icon name="search" class="cursor-pointer" @click="focus" />
      </template>

      <!-- using q-input clearable prop sets val to null, but app is built expecting empty string in many places -->
      <template v-if="isClearable && val" slot="append">
        <q-icon name="cancel" class="iconClear" @click.stop="setValue('')" />
      </template>

      <template v-if="after" slot="after">
        <q-icon :name="after.icon" class="cursor-pointer" @click="after.handler" />
      </template>
    </q-input>

  </div>
</template>

<script>
import hhCheckboxGroup from './hhCheckboxGroup.vue'
import hhSelectMultiple from './hhSelectMultiple.vue'
import hhFileUpload from './hhFileUpload.vue'
import hhDateTime from './hhDateTime.vue'
import hhDuration from './hhDuration.vue'
import hhPhoneInput from './hhPhoneInput.vue'

export default {
  name: 'hh-text-input',
  props: ['label', 'type', 'value', 'options', 'format', 'suffix', 'defaultDate', 'placeholder', 'autocomplete', 'min', 'max', 'disabled', 'preview', 'after', 'autofocus', 'error', 'files', 'uploadAPIData', 'hideUnderline', 'color', 'nodeletefile', 'defaultPhoneCountry', 'autogrow', 'fontSize'],

  data () {
    return {
      val: undefined,
      isFocused: false,
      hasError: false,
      showPassword: false,
      autocompleteOptions: this.autocomplete ? this.autocomplete.options : []
    }
  },

  components: {
    hhDateTime,
    hhCheckboxGroup,
    hhSelectMultiple,
    hhFileUpload,
    hhPhoneInput,
    hhDuration
  },

  methods: {
    autocompleteFilter (val, update, abort) {
      const context = this;
      update(() => {
        const needle = val.toLocaleLowerCase()
        context.autocompleteOptions = context.autocomplete.options.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
      })
    },

    focus: function () { this.$refs.inputField.focus(); },

    setValue: function (v) {
      this.val = v
      this.$emit('input', v);
    },

    setError: function (hasError) {
      this.hasError = hasError;
    },

    inputHasError: function () {
      return this.hasError;
    },

    setFocused () {
      this.isFocused = true;
    },

    setBlurred () { this.isFocused = false; }
  },

  computed: {
    inputType () {
      if (this.showPassword) { return 'text'; }
      return this.type;
    },

    labelColor: function () {
      return this.$store.getters.colorForTextOnWhiteBackground;
    },

    isDisabled: function () { return this.disabled ? true : false; },
    isPreview: function () { return this.preview ? true : false; },
    isAutogrow: function () { return this.type === 'textarea' && this.autogrow; },

    isDateInput: function () {
      if (this.type === 'datetime') { return true; }
      if (this.type === 'date') { return true; }
      if (this.type === 'time') { return true; }
      return false;
    },

    isMultiSelectInput: function () {
      if (this.type === 'multiselect') { return true; }
      return false;
    },

    isSelectInput: function () {
      if (this.type === 'select') { return true; }
      return false;
    },

    isSelectAutocomplete: function () {
      return (this.autocomplete && 'options' in this.autocomplete && this.autocomplete.options.length > 0);
    },

    isSearchInput: function () {
      if (this.type === 'search') { return true; }
      return false;
    },

    isClearable: function () {
      // Clearable icon is tabindex focusable, so it may be best not to use it on text/textareas for accidental deletion
      if (this.type === 'text' || this.type === 'textarea' || this.type === 'password') { return false; }
      if (this.value === '' || !this.isFocused) { return false; }
      return true;
    },

    defaultDateObject () {
      if (this.defaultDate instanceof Date) { return this.defaultDate; }
      if (typeof this.defaultDate === 'number') { return new Date(this.defaultDate); }
      if (typeof this.defaultDate === 'string') { return new Date(this.defaultDate); }
      return null;
    },

    canDeleteFile () {
      return this.nodeletefile ? false : true;
    }
  },

  watch: {
    value (value) {
      this.val = value;
    }
  },

  created () {
    // Set the passed-in property value to our v-model val
    if (this.value !== undefined && this.value !== null) {
      this.val = this.value;
    }
  }
}
</script>

<style lang="stylus">
div.hhInputField
  box-sizing: border-box
  padding: 5px 10px
  margin: 0
  margin-top: 1px
  background-color: white
  font-size: 14px
  font-weight: bold
  color: #555

  &.hasError
    border-left: 3px solid rgb(233, 30, 99)
    padding-left: 7px

  > .error
    color: rgb(233, 30, 99)
    font-size: 13px
    font-weight: bold
    padding-bottom: 2px

  .hhInputFieldLabel
    display: block
    text-transform: uppercase
    opacity: 0.65

  .iconClear
    cursor: pointer
    opacity: 0.6

    &:hover
      opacity: 1
</style>
