<template>
  <q-input ref="qDateInput" v-model="model" type="text" dense hide-bottom-space :disable="disabled" @focus="wasFocused" class="hhDateTimeInput">
    <template slot="append">
      <div v-if="suffix" style="margin-right: 10px; font-size: 16px;">{{ suffix }}</div>
      <q-icon :name="canSelectDate ? 'event' : 'access_time'" class="cursor-pointer">
        <q-dialog ref="qDateProxy" transition-show="scale" transition-hide="scale" @hide="$refs.qDateInput.blur()">
          <q-date v-if="canSelectDate" v-model="model" :mask="maskFormat" @input="dateSelected" :options="isDateAllowed" />
          <q-time v-if="canSelectTime" v-model="model" :mask="maskFormat" @input="timeSelected">
            <!-- cannot close the time modal without changing it, so if value already exists, offer a cancel button -->
            <!-- however, if the model was just set from a default, change label to 'close' since something happened -->
            <div v-if="model" style="text-align: center;">
              <hhSmallButton @click="$refs.qDateProxy.hide()" style="margin: 10px;">{{ modelSetFromDefault ? 'Close' : 'Cancel' }}</hhSmallButton>
            </div>
          </q-time>
        </q-dialog>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
import { date } from 'quasar'

import hhSmallButton from './hhSmallButton.vue'

export default {
  name: 'hh-datetime-input',

  props: {
    type: { type: String, default: 'date' },
    value: Date,
    min: [String, Date],
    max: [String, Date],
    format: String,
    defaultDate: Date,
    suffix: String,
    disabled: { type: Boolean, default: false }
  },

  data () {
    return {
      model: '',
      modelSetFromDefault: false
    }
  },

  components: {
    hhSmallButton
  },

  watch: {
    // respond to any changes to the reactive v-model property after data() is initialized
    value () {
      this.model = this.modelFromValue();
    }
  },

  computed: {
    canSelectDate () { return this.type !== 'time'; },
    canSelectTime () { return this.type === 'time' || this.type === 'datetime'; },
    minDate () { return this.min ? new Date(this.min) : null; },
    maxDate () { return this.max ? new Date(this.max) : null; },

    maskFormat () {
      if (this.format) { return this.format; }
      if (this.type === 'time') { return 'h:mm A'; }
      if (this.type === 'datetime') { return 'MMM D, YYYY h:mm A'; }
      return 'MMM D, YYYY';
    }
  },

  methods: {
    dateSelected (value, reason, details) {
      this.$refs.qDateProxy.hide();

      const oldValue = this.value ? this.value : new Date();
      const newValue = new Date(details.year, details.month - 1, details.day,
        oldValue.getHours(), oldValue.getMinutes(), 0, 0);

      this.$emit('input', newValue);
    },

    timeSelected (value, details) {
      this.$refs.qDateProxy.hide();

      let oldValue = this.value;
      if (!oldValue) {
        oldValue = this.defaultDate ? this.defaultDate : new Date();
      }

      const newValue = new Date(oldValue.getFullYear(), oldValue.getMonth(), oldValue.getDate(),
        details.hour, details.minute, 0, 0);

      this.$emit('input', newValue);
    },

    isDateAllowed (dateString) {
      const date = new Date(dateString);
      if (this.minDate && date < this.minDate) { return false; }
      if (this.maxDate && date > this.maxDate) { return false; }
      return true;
    },

    wasFocused () {
      this.modelSetFromDefault = false;
      // If there is no model set and a default date exists, emit that so it is populated and then show picker...
      if (this.model === '' && this.defaultDate) {
        this.modelSetFromDefault = true;
        this.$emit('input', this.defaultDate);
      }

      this.$refs.qDateProxy.show();
    },

    modelFromValue () {
      return this.value ? date.formatDate(new Date(this.value), this.maskFormat) : '';
    }
  },

  created () {
    this.model = this.modelFromValue();
  }
}
</script>
