<template>
  <hhScreen v-if="isUserLoggedIn">
    <template v-for="homeitem in homeItems">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <div v-if="homeItemIsMenuButton(homeitem)" class="homeItemMenuButton" @click="menuItemActions[homeitem.type]()" :style="{ backgroundImage: $store.getters.homeItemMenuButtonBackgroundImage }">
        <q-icon :name="homeitem.icon" color="white" />
        <b>{{ homeitem.title }}</b>
        <div v-if="menuItemBubbleText(homeitem)" class="bubble">{{ menuItemBubbleText(homeitem) }}</div>
        <q-icon name="keyboard_arrow_right" />
      </div>

      <template v-else-if="homeitem.type == 'contentUpcomingRecent'">
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <div v-if="showToday" class="hhOppSection">
          <h1 :style="{ color: $store.getters.colorForTextOnAppBackground }">Today</h1>
          <hhOpportunityRow v-for="row in opportunitiesToday" :key="row.timeslots[0].id"
            :opportunity="row.opportunity" :timeslots="row.timeslots" :commitments="row.commitments"
            @select="opportunityClicked(row.opportunity)" />
        </div>

        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <div v-if="showTomorrow" class="hhOppSection">
          <h1 :style="{ color: $store.getters.colorForTextOnAppBackground }">Tomorrow</h1>
          <hhOpportunityRow v-for="row in opportunitiesTomorrow" :key="row.timeslots[0].id"
            :opportunity="row.opportunity" :timeslots="row.timeslots" :commitments="row.commitments"
            @select="opportunityClicked(row.opportunity)" />
        </div>

        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <div v-if="showRecent" class="hhOppSection">
          <h1 :style="{ color: $store.getters.colorForTextOnAppBackground }">Recently attended</h1>
          <hhOpportunityRow v-for="row in opportunitiesRecent" :key="row.timeslots[0].id"
            :opportunity="row.opportunity" :timeslots="row.timeslots" :commitments="row.commitments"
            :commitment="row.commitments[0]" :hidePinToTop="true" @select="commitmentClicked(row.commitments[0])" />
          <div @click="toPastCommitments" class="morelink" :style="{ color: $store.getters.colorForTextOnAppBackground }">see all of my past commitments <q-icon name="keyboard_arrow_right" :style="{ color: $store.getters.colorForTextOnAppBackground }"/></div>
        </div>

        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <div v-if="showUpcoming" class="hhOppSection">
          <h1 :style="{ marginTop: showRecent ? '10px' : '15px', color: $store.getters.colorForTextOnAppBackground }">Upcoming opportunities</h1>
          <hhOpportunityRow v-for="row in opportunitiesUpcoming" :key="row.timeslots[0].id"
            :opportunity="row.opportunity" :timeslots="row.timeslots" :commitments="row.commitments"
            @select="opportunityClicked(row.opportunity)" />
          <div @click="toOpportunities" class="morelink" :style="{ color: $store.getters.colorForTextOnAppBackground }">See all upcoming opportunities <q-icon name="keyboard_arrow_right" :style="{ color: $store.getters.colorForTextOnAppBackground }" /></div>
        </div>
      </template>

      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <div v-else-if="homeitem.type == 'contentInfoBox'" class="hhHomeInfoBox" @click="homeItemClicked(homeitem)"
        :style="{ cursor: 'link' in homeitem && homeitem.link != '' ? 'pointer' : 'auto' }">
        <div v-if="homeitem.header !== ''" class="heading">{{ homeitem.header }}</div>
        <div v-if="homeitem.header !== '' && (homeitem.title !== '' || homeitem.content !== '')" class="headingspacer"></div>
        <div v-if="homeitem.title !== ''" class="title">{{ homeitem.title }}</div>
        <div v-if="homeitem.content !== '' && homeitem.title !== ''" class="contentspacer"></div>
        <div v-if="homeitem.content !== ''" class="content">{{ homeitem.content }}</div>
      </div>

      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <div v-else-if="homeitem.type == 'contentFeaturedGoals' && institutionGoals.length > 0" class="hhGoalSection">
        <hhGoalAwardDetailsModal />
        <h1 v-if="homeitem.header !== ''" :style="{ color: $store.getters.colorForTextOnAppBackground }">{{ homeitem.header }}</h1>
        <hhGoalSummary v-for="goal in institutionGoals" :goal="goal" :key="'goal-' + goal.id" />
      </div>

      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <div v-else-if="homeitem.type == 'contentFeaturedSurveys' && institutionSurveys.length > 0" class="hhSurveySection">
        <h1 v-if="homeitem.header !== ''" :style="{ color: $store.getters.colorForTextOnAppBackground }">{{ homeitem.header }}</h1>
        <hhSurveyRow v-for="survey in institutionSurveys" :survey="survey" :key="'survey-' + survey.id"
          @click.native="surveyClicked(survey)" />
      </div>

      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <div v-else-if="homeitem.type == 'contentVTOBalance'" class="hhVTOSection" :style="{ color: $store.getters.colorForTextOnAppBackground }">
        <div>{{ vtoStrings['homebalance'] }}</div>
        <div>{{ vtoRemaining }}</div>
      </div>

      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <div v-else-if="homeitem.type == 'contentBannerImage'" class="hhHomeAdvert">
        <a v-if="'link' in homeitem && homeitem.link != ''" :href="homeitem.link">
          <img :src="homeitem.src">
        </a>
        <img v-else :src="homeitem.src">
      </div>

    </template>

    <!-- if we add the select institution box, add another div for padding so content isn't hidden by fixed position -->
    <hhSelectInstitution v-if="validUserInstitutionCount > 1" forceDark slot="footer" class="hhSelectInstitutionHome bg-darken" />
  </hhScreen>
</template>

<script>
import { mapGetters } from 'vuex'
import hhScreen from './hhScreen.vue'
import hhOpportunityRow from '../components/hhOpportunityRow.vue'
import hhGoalSummary from '../components/hhGoalSummary.vue'
import hhGoalAwardDetailsModal from '../components/hhGoalAwardDetailsModal.vue'
import hhSurveyRow from '../components/hhSurveyRow.vue'
import hhSelectInstitution from '../components/hhSelectInstitution.vue'
import hhUtils from '../utils'
import hhSurveysMixin from '../mixins/hhSurveysMixin'

export default {
  name: 'hh-home-page',
  data () {
    return {
      oppShownOnDate: {}
    }
  },
  mixins: [ hhSurveysMixin ],
  components: {
    hhScreen,
    hhOpportunityRow,
    hhGoalSummary,
    hhGoalAwardDetailsModal,
    hhSurveyRow,
    hhSelectInstitution
  },
  computed: {
    ...mapGetters(['isUserLoggedIn', 'homeCachedLookup', 'userInstitutions', 'validUserInstitutionCount',
      'getOpportunityList', 'getOpportunity', 'getOpportunityTimeslots', 'getTimeslot', 'getCommitment', 'getSurvey',
      'activeInstitution', 'vtoEnabled', 'vtoBalanceAvailable', 'vtoStrings', 'featuredGoals', 'featuredSurveys']),

    vtoRemaining () {
      return hhUtils.formatDuration(this.vtoBalanceAvailable, { style: 'tiny' });
    },

    opportunityList () { return this.getOpportunityList(this.homeCachedLookup); },

    homeItems () {
      if (this.activeInstitution && this.activeInstitution.homeitems) {
        var homeItems = [];
        for (var i = 0; i < this.activeInstitution.homeitems.length; i++) {
          var homeItem = this.activeInstitution.homeitems[i];
          if (homeItem.type === 'menuUserProfile' && homeItem.ifunanswered > 0) {
            if (this.activeInstitution.profile && this.activeInstitution.profile.response > 0) {
              continue;
            }
          }

          homeItems.push(homeItem);
        }
        return homeItems;
      }
      return [
        { type: 'menuMyCommits', title: 'My Commitments', icon: 'location_on' },
        { type: 'menuPersonalStats', title: 'View Stats & Goals', icon: 'stars' },
        {
          type: 'contentInfoBox',
          header: 'Currently inactive in all participating organizations',
          title: 'Thank you for using Helper Helper',
          content: 'Your Helper Helper account is not actively associated with any organizations using this application. You may still view your commitment history and personal stats.\n\nIf you currently participate with an organization that could benefit from Helper Helper, please let us know by emailing info@helperhelper.com.'
        }
      ];
    },

    showToday () { return this.opportunitiesToday.length > 0; },
    opportunitiesToday () {
      var now = new Date();
      return this.opportunitiesMatchingDay(now);
    },

    showTomorrow () { return this.opportunitiesTomorrow.length > 0; },
    opportunitiesTomorrow () {
      var now = new Date();
      now.setDate(now.getDate() + 1);
      return this.opportunitiesMatchingDay(now);
    },

    showRecent () { return this.opportunitiesRecent.length > 0; },
    opportunitiesRecent () {
      if (!this.homeCachedLookup.commitmentList) { return []; }

      var now = new Date();
      var matchingOpps = [];
      var twoWeeks = 1000 * 60 * 60 * 24 * 14;

      var commitids = this.homeCachedLookup.commitmentList;
      for (let i = 0; i < commitids.length; i++) {
        const commitment = this.getCommitment(commitids[i], this.homeCachedLookup);
        if (!commitment) { continue; } // Commitment may have been deleted...

        const timeslot = this.getTimeslot(commitment.timeslotid, this.homeCachedLookup);
        const opportunity = this.getOpportunity(commitment.opportunityid, this.homeCachedLookup);

        // we aren't interested in 'ispast' commitments
        if (!opportunity || !timeslot || commitment.ispast) { continue; }

        const oppDate = new Date(timeslot.start);
        if (!oppDate) { continue; }

        // Only show recent commitments in last two weeks
        const timeDifference = now - oppDate;
        if (timeDifference < 0 || timeDifference > twoWeeks) { continue; }

        // Don't show this opportunity in two places on home screen
        if (this.opportunityInList(opportunity.id, this.opportunitiesToday)) { continue; }

        // Skip waitlisted and rejected commitments, no need to show those in recent commitments list
        var mode = hhUtils.commitmentMode(commitment, timeslot);
        if (mode === 'rejected' || mode === 'waitlisted') { continue; }

        // Skip validated commitments unless there are unanswered reflection surveys...
        if (mode === 'validated') {
          if (!this.surveysCommitmentHasUnansweredReflections(commitment, opportunity)) {
            continue;
          }
        }

        matchingOpps.push({
          opportunity: opportunity,
          timeslots: [timeslot],
          commitments: [commitment]
        });
      }

      matchingOpps.sort(this.sortTimeslots);

      // Show most recent first, cap at showing three most recent...
      return matchingOpps.reverse().splice(0, 3);
    },

    showUpcoming () { return this.opportunitiesUpcoming.length > 0; },
    opportunitiesUpcoming () {
      var now = new Date();
      var matchingOpps = [];
      var twoWeeks = 1000 * 60 * 60 * 24 * 14;

      for (let i = 0; i < this.opportunityList.length; i++) {
        const oppID = this.opportunityList[i];

        // Don't show this opportunity in two places on home screen
        if (this.opportunityInList(oppID, this.opportunitiesToday)) { continue; }
        if (this.opportunityInList(oppID, this.opportunitiesTomorrow)) { continue; }

        const slots = [], commits = [];
        const oppslots = this.getOpportunityTimeslots(oppID, this.homeCachedLookup);
        for (let j = 0; j < oppslots.length; j++) {
          const slot = this.getTimeslot(oppslots[j], this.homeCachedLookup);
          const start = new Date(slot.start);
          if (!start) { continue; }

          // Only show upcoming opportunities in two weeks
          const timeDifference = start - now;
          if (timeDifference < 0 || timeDifference > twoWeeks) { continue; }

          slots.push(slot);

          if (slot.commitmentid) {
            const commit = this.getCommitment(slot.commitmentid, this.homeCachedLookup);
            if (commit) { commits.push(commit); }
          }
        }

        if (slots.length) {
          matchingOpps.push({
            opportunity: this.getOpportunity(oppID, this.homeCachedLookup),
            timeslots: slots,
            commitments: commits
          });
        }
      }

      return matchingOpps;
    },

    institutionGoals () {
      return this.featuredGoals.filter(goal => this.activeInstitution.id === goal.institution);
    },

    institutionSurveys () {
      // If there is no active institution, do not return any surveys...
      if (!this.activeInstitution) { return []; }

      const context = this;
      const surveys = this.featuredSurveys.reduce(function (list, userSurvey) {
        if (!userSurvey.institution || context.activeInstitution.id !== userSurvey.institution.id) { return list; }

        const survey = context.getSurvey(userSurvey.id);
        if (survey) { list.push(survey); }
        return list;
      }, []);

      surveys.sort(function (a, b) { return a.title.localeCompare(b.title, 'en', { sensitivity: 'base' }); });
      return surveys;
    }
  },
  methods: {
    homeItemIsMenuButton (homeitem) {
      return (homeitem.type in this.menuItemActions);
    },

    menuItemBubbleText (homeitem) {
      var bubbleText = 'bubble' in homeitem && homeitem.bubble !== '' ? homeitem.bubble : '';
      if (homeitem.type === 'menuMyCommits') {
        bubbleText = this.$store.getters.myCommitmentsBubbleLabelText(homeitem.bubble);
      }
      else if (homeitem.type === 'menuSurveys') {
        var availableCount = this.$store.getters.userSurveys.length;
        bubbleText = availableCount > 0 ? availableCount : '';
      }

      return bubbleText;
    },

    homeItemClicked (homeitem) {
      if ('link' in homeitem && homeitem.link !== '') {
        window.location = homeitem.link;
      }
    },

    opportunityClicked (opportunity) {
      this.$store.commit('pushBackRoute', this.$route.path);
      this.$router.push('/opportunity/' + opportunity.id);
    },
    commitmentClicked (commitment) {
      this.$store.commit('pushBackRoute', this.$route.path);
      this.$router.push('/commitment/' + commitment.id);
    },

    surveyClicked (survey) {
      this.$store.commit('pushBackRoute', this.$route.path);
      this.$router.push('/survey/' + survey.id);
    },

    sortOpportunityCommitments (oppinfoa, oppinfob) {
      var aTime = 0, bTime = 0;
      var aCommitTime = null, bCommitTime = null;

      if (oppinfoa.timeslots.length) { aTime = Date.parse(oppinfoa.timeslots[0].start); }
      if (oppinfoa.commitments.length) { aCommitTime = Date.parse(oppinfoa.commitments[0].start); }

      if (oppinfob.timeslots.length) { bTime = Date.parse(oppinfob.timeslots[0].start); }
      if (oppinfob.commitments.length) { bCommitTime = Date.parse(oppinfob.commitments[0].start); }

      // Sort items with commitments higher than those without...
      if (aCommitTime) {
        if (!bCommitTime) { return -1; }
        return aCommitTime - bCommitTime;
      }
      else if (bCommitTime) { return 1; }

      // Otherwise, sort by start date...
      if (aTime < bTime) { return -1; }
      if (aTime > bTime) { return 1; }

      // Otherwise, sort by opportunity name...
      var aOpp = oppinfoa.opportunity;
      var bOpp = oppinfob.opportunity;
      if (aOpp.name < bOpp.name) { return -1; }
      if (aOpp.name > bOpp.name) { return 1; }

      return 0;
    },

    sortTimeslots (a, b) {
      a = a.timeslots[0]; b = b.timeslots[0];
      var aTime = Date.parse(a.start);
      var bTime = Date.parse(b.start);
      if (aTime < bTime) { return -1; }
      if (aTime > bTime) { return 1; }
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    },

    toRoute (path) {
      this.$store.commit('clearBackRoutes');
      this.$store.commit('pushBackRoute', this.$route.path);
      this.$router.push(path);
    },

    // If the user clicks from home screen, refresh the opportunity list...
    toOpportunities () {
      this.$store.commit('setUserOpportunityListReloadNeeded');
      this.$store.commit('pushBackRoute', this.$route.path);
      this.$router.push('/opportunities');
    },
    toPastCommitments () { this.toRoute('/commitments/past'); },
    toSurveys () {
      this.$hhAPI.getInstitutionSurveysAvailable();
      this.toRoute('/surveys');
    },
    toUserProfile () {
      var userProfileRoute = '/settings/profile/' + this.activeInstitution.id;
      this.toRoute(userProfileRoute);
    },

    opportunityInList (oppID, oppList) {
      for (let i = 0; i < oppList.length; i++) {
        if (oppID === oppList[i].opportunity.id) { return true; }
      }
      return false;
    },

    opportunitiesMatchingDay (date) {
      var matchingOpps = [];
      var now = Date.now();
      var day = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();

      for (let i = 0; i < this.opportunityList.length; i++) {
        const oppID = this.opportunityList[i];
        const oppSlots = this.getOpportunityTimeslots(oppID, this.homeCachedLookup);

        var slots = [], commits = [];
        for (let j = 0; j < oppSlots.length; j++) {
          const slot = this.getTimeslot(oppSlots[j], this.homeCachedLookup);
          if (!slot) { continue; }

          // Don't include time slots where the end time has passed, as the opportunity page will not show them.
          // Although it would be nice for recently attended items that were for 'today' to appear in that section,
          // because they link to the opportunity screen, it can cause issues where time slot doesn't appear.
          const start = new Date(slot.start);
          const durationMilliseconds = slot.duration * 60 * 1000;
          if (start.getTime() + durationMilliseconds < now) { continue; }

          const slotday = start.getFullYear() + '-' + start.getMonth() + '-' + start.getDate();
          if (slotday === day) {
            slots.push(slot);

            if (slot.commitmentid) {
              const commit = this.getCommitment(slot.commitmentid, this.homeCachedLookup);
              if (commit) { commits.push(commit); }
            }
          }
        }

        if (slots.length) {
          matchingOpps.push({
            opportunity: this.getOpportunity(oppID, this.homeCachedLookup),
            timeslots: slots,
            commitments: commits
          });
        }
      }

      matchingOpps.sort(this.sortOpportunityCommitments);

      return matchingOpps.slice(0, 3);
    }
  },
  created () {
    var context = this;
    this.menuItemActions = {
      menuFindOpps: this.toOpportunities,
      menuMyCommits: function () { context.toRoute('/commitments') },
      menuOngoingOpps: function () { context.toRoute('/opportunities/ongoing') },
      menuSurveys: this.toSurveys,
      menuPersonalStats: function () { context.toRoute('/stats'); },
      menuTeamImpacts: function () { context.toRoute('/impacts'); },
      menuAddPastCommit: function () { context.toRoute('/commitments/add-past'); },
      menuRequestVTO: function () { context.toRoute('/commitments/request-vto'); },
      menuAbout: function () { context.toRoute('/about'); },
      menuUserProfile: this.toUserProfile
    };
  }
}
</script>

<style lang="stylus">
.homeItemMenuButton
  display: flex
  align-items: center
  color: white
  text-align: left
  cursor: pointer
  margin: 2px 0px
  padding: 15px
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1))
  font-size: 10px

  b
    flex: 1
    font-size: 16px

  .bubble
    background-color: rgba(255,255,255,0.2)
    text-shadow: 0px 0px 4px rgba(0,0,0,0.3)
    font-size: 13px
    font-weight: bold
    padding: 5px 10px
    border-radius: 10px
    margin-left: 10px

  .q-icon
    font-size: 22px

  .q-icon:first-child
    margin-right: 10px

  .q-icon:last-child
    margin-right: -5px
    margin-left: 10px

.hhHomeAdvert
  margin: 10px 0px
  text-align: center

  img
    max-width: 100%

.hhHomeInfoBox
  background-color: rgba(255, 255, 255, 0.9)
  color: #555
  padding: 10px
  margin: 15px 0px

  &:first-child
    margin-top: 0px

  .heading
    font-size: 11px

  .headingspacer
    margin-top: 2px

  .title
    font-size: 18px
    font-weight: bold

  .contentspacer
    margin-top: 8px

  .content
    font-size: 14px
    white-space: pre-wrap
    word-wrap: break-word

.hhOppSection
  .morelink
    text-align: right
    text-transform: lowercase
    color: white
    font-size: 16px
    margin: 10px
    cursor: pointer

.hhGoalSection
  margin: 10px 0px

.hhSurveySection
  margin: 10px 0px

.hhOppSection > h1, .hhGoalSection > h1, .hhSurveySection > h1
  margin: 10px
  margin-top: 15px
  font-size: 24px
  color: white
  font-weight: normal
  line-height: normal
  letter-spacing: normal

.hhVTOSection
  max-width: 360px
  margin: 10px auto
  padding: 0px 10px
  display: flex
  justify-content: space-between
  color: #fff

  div:first-child
    font-size: 14px
    line-height: 16px
    font-weight: bold
    text-transform: uppercase

  div:last-child
    font-size: 16px
    line-height: 16px
    font-weight: bold

/* the following needs both box-shadow and filter to show the shadow above scroll contents */
.hhSelectInstitutionHome
  border-top: 1px solid rgba(0, 0, 0, 0.4)
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2)
  filter: drop-shadow(0 0 30px #333)
</style>
