<template>
  <div v-if="goal" class="hhGoalDetails">
    <div v-if="goal.teamid || goal.branchid" style="display: flex; color: #777; font-size: 16px; border: 1px solid #ccc; padding: 10px; margin-bottom: 10px;">
      <q-icon name="info_outline" style="font-size: 40px; margin-right: 5px;" />
      <div>This is a team goal that is achieved by the combined effort of all members of <b>{{ goalTargetName }}</b></div>
    </div>

    <div v-if="goalHasMultipleTiers" class="hhGoalName">{{ goal.name }}</div>
    <div v-for="(tier, tIndex) in goal.tiers" class="hhTierDetails" :key="'hh-goal-award-tier-' + tier.id" :style="{ marginTop: tIndex > 0 ? '10px' : '0px' }">
      <q-knob v-if="tier.progress < 100" v-model="tier.progress" class="progressKnob"
        size="40px" font-size="16px" color="goal-progress" track-color="grey-4" readonly show-value />
      <q-icon v-else name="stars" class="awardedIcon" :style="{ color: awardedIconColor }" />

      <div style="flex: 1;">
        <div class="hhTierName" :style="{ marginTop: tierHasMultipleRequirements(tier) ? '10px' : '0px' }">{{
          tier.name ? tier.name : goal.name
        }}</div>
        <div v-if="tierHasMultipleRequirements(tier)">
          <div>{{ tier.match == 'any' ? 'any' : 'all' }} of the following:</div>
          <div v-for="req in tier.requirements" :key="'hh-goal-award-requirement-' + req.id"
            class="hhTierRequirement">
            <q-knob v-if="req.progress < 100" v-model="req.progress" class="progressKnob" :thickness="1"
              size="21px" color="goal-progress" track-color="grey-4" readonly />
            <q-icon v-else name="check_circle" color="goal-progress" class="completedIcon" />

            <div style="flex: 1;">
              <div style="font-weight: bold;">{{ req.name }}</div>
              <div>{{ requirementProgressStatus(req) }}</div>
            </div>
          </div>
        </div>
        <div v-else class="hhSingleRequirement">{{ requirementProgressStatus(tier.requirements[0]) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import hhUtils from '../utils'

export default {
  name: 'hh-goal-award-details',

  props: ['goal'],

  computed: {
    goalHasMultipleTiers () { return this.goal.tiers.length > 1; },

    goalTargetName () {
      if (this.goal.teamid) {
        if ('branchname' in this.goal && this.goal.branchname !== '') {
          return this.goal.branchname + ' / ' + this.goal.teamname;
        }
        return this.goal.teamname;
      }

      if (this.goal.branchid) {
        return this.goal.branchname;
      }

      return '';
    },

    awardedIconColor () {
      return this.$store.getters.colorForBackgroundWithWhiteText;
    }
  },

  methods: {
    tierHasMultipleRequirements (tier) {
      return tier.requirements.length > 1;
    },

    requirementProgressStatus (req) {
      if (req.type === 'C') {
        const eventsComplete = parseInt(req.amount);
        const eventsTarget = parseInt(req.target);
        const commitmentLabel = eventsTarget === 1 ? 'commitment' : 'commitments';

        if (eventsComplete >= eventsTarget) { return eventsTarget + ' ' + commitmentLabel; }
        return eventsComplete + ' of ' + eventsTarget + ' ' + commitmentLabel;
      }
      else {
        const minutesComplete = parseInt(Math.round(req.amount * 60));
        const minutesTarget = parseInt(Math.round(req.target * 60));
        const hoursComplete = minutesComplete === 0 ? 0 : hhUtils.formatDuration(minutesComplete, { style: 'tiny' });
        const hoursTarget = hhUtils.formatDuration(minutesTarget);

        if (minutesComplete >= minutesTarget) { return hoursTarget; }
        return hoursComplete + ' of ' + hoursTarget;
      }
    }
  }
}
</script>

<style lang="stylus">
.hhGoalDetails
  padding: 10px;
  background-color: white;
  color: #555;

  .hhGoalName
    font-size: 1.4em;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;

  .hhTierDetails
    display: flex;
    align-items: flex-start;

    .hhTierName
      font-weight: bold;
      font-size: 1.2em;
      margin-top: 5px;

    .progressKnob
      margin-right: 5px;
      position: relative;
      top: 1px;

    .awardedIcon
      margin-right: 5px;
      font-size: 44px;
      line-height: 44px;
      position: relative;
      top: 2px;
      width: 40px;
      height: 40px;

    .hhTierRequirement
      display: flex
      align-items: flex-start;
      margin-top: 10px;

      .progressKnob
        top: -1px;

      .completedIcon
        font-size: 25px;
        line-height: 25px;
        margin-top: -3px;
        margin-right: 3px;
        margin-left: -2px;

  .text-goal-progress
    color: #00c851
</style>
