import Vue from 'vue'
import Vuex from 'vuex'
import { Cookies } from 'quasar'
import hhUtils from '../utils';

Vue.use(Vuex)

/* cachedLookup has the following typical format, which is populated with data as it comes in...
cachedLookup: {
  // these are lookup hashes to match an id to an object
  opportunityDetails: {},
  commitmentDetails: {},
  timeslotDetails: {},
  surveyDetails: {}, // Added API v3.2 in April, 2020

  // commitment list is a list of commitments stored when user logs in that we use on the home screen of the app
  // opportunityList and opportunityTimeslots function similar to below, but for home screen only
  home: {
    opportunityList: [],
    commitmentList: [],
    opportunityTimeslots: {},
  },

  // opportunityList contains a list of opportunity ids for use on find opportunities screen
  // opportunityTimeslots maps an opportunity id to a list of timeslot ids
  // ongoing count lists the number of ongoing opportunities available
  // ongoing opportunity list is a list of opportunity ids for use on find ongoing opportunities screen
  user: {
    opportunityList: [],
    opportunityTimeslots: {},
    ongoingCount: 0,
    ongoingOpportunityList: [],
    opportunityReloadNeeded: false,
    ongoingOpportunityReloadNeeded: false,
  },

  // a lookup for each inst, inst6 is the key for the demo account with inst id of 6
  // these are used for public institution pages and public opportunities
  // in addition to the similar keys as found in user, it also contains 'about' with institution information
  // and teams with a list of teams that a user can sign up for, if enabled...
  inst[instID]: {
    about: {},
    opportunityList: [],
    opportunityTimeslots: {},
    ongoingCount: 0,
    ongoingOpportunityList: [],
    teams: []
  },

  // A list of countries used by the telephone input prompts...
  countryPhoneInfo: {
    list: [{ code: 'US', name: 'United State', phone: '+1' }, ...],
    iplookup: 'US', // Country code lookup from requesting ip address
  }
}
*/

var blankState = {
  user: {
    id: 0,
    firstname: '',
    lastname: '',
    phone: '',
    notifications: {
      email: 1,
      push: 1
    },
    institutions: []
  },
  cachedLookup: {},
  isLoadingCountryPhoneInfo: false,
  upcomingCommitmentsBubbleCount: -1,
  activeInstitution: null,
  nextToast: null,
  backRoutes: [],
  pageConfig: null,
  attemptingLogin: false
};

const store = new Vuex.Store({
  // We must copy the blankState object or it will be used by reference and we need it later...
  state: JSON.parse(JSON.stringify(blankState)),
  getters: {
    activeInstitution: function (state) { return state.activeInstitution; },
    isUserLoggedIn: function (state) {
      if (!state.user || !state.user.id) {
        return false;
      }
      return true;
    },
    logoURL: function (state) {
      if (state.pageConfig && state.pageConfig.logourl) {
        return state.pageConfig.logourl;
      }
      if (state.activeInstitution && state.activeInstitution.logourl) {
        return state.activeInstitution.logourl;
      }
      return '/images/hh-logo.png';
    },
    showBackground: function (state, getters) {
      if (state.pageConfig && state.pageConfig.hidebackground) {
        return false;
      }
      return true;
    },
    showSidebar: function (state, getters) {
      if (state.pageConfig && state.pageConfig.hidesidebar) {
        return false;
      }
      return getters.isUserLoggedIn;
    },
    showLogo: function (state) {
      if (state.pageConfig && state.pageConfig.hidelogo) {
        return false;
      }
      return true;
    },
    showBackButton: function (state, getters) {
      if (state.pageConfig && state.pageConfig.hideback) { return false; }
      if (state.backRoutes.length < 1) { return false; }
      return true;
    },
    userGoals: function (state) {
      if (!state.user || !state.user.goals) { return []; }
      return state.user.goals;
    },
    featuredGoals: function (state, getters) {
      return getters.userGoals.filter(goal => 'featured' in goal && goal.featured > 0);
    },
    areUserSurveysEnabled: function (state) {
      if (state.user && state.user.options && 'surveys' in state.user.options) {
        return state.user.options.surveys ? true : false;
      }
      return false;
    },
    userSurveys: function (state) {
      if (!state.user || !state.user.surveys) { return []; }
      return state.user.surveys;
    },
    featuredSurveys: function (state, getters) {
      return getters.userSurveys.filter(survey => 'featured' in survey && survey.featured > 0);
    },
    userInstitutions: function (state) {
      if (!state.user || !state.user.institutions) { return []; }
      return state.user.institutions;
    },
    validUserInstitutions: function (state, getters) {
      var userInstitutions = getters.userInstitutions;
      var validInstitutions = [];
      for (var i = 0; i < userInstitutions.length; i++) {
        if (userInstitutions[i].valid) { validInstitutions.push(userInstitutions[i]); }
      }
      return validInstitutions;
    },
    validUserInstitutionCount: function (state, getters) {
      return getters.validUserInstitutions.length;
    },

    // Individual opportunity, timeslot, and commitment details are stored at top level so we always use latest info
    getOpportunity: (state) => (oppID) => {
      return _lookup(state.cachedLookup, 'opportunityDetails', oppID.toString());
    },
    getTimeslot: (state) => (slotID) => {
      return _lookup(state.cachedLookup, 'timeslotDetails', slotID.toString());
    },
    getCommitment: (state) => (commitID) => {
      return _lookup(state.cachedLookup, 'commitmentDetails', commitID.toString());
    },
    getSurvey: (state) => (surveyID) => {
      return _lookup(state.cachedLookup, 'surveyDetails', surveyID.toString());
    },

    isLoadingCountryPhoneInfo (state) { return state.isLoadingCountryPhoneInfo; },
    countryPhoneInfo (state) {
      if ('countryPhoneInfo' in state.cachedLookup) { return state.cachedLookup.countryPhoneInfo; }
      return [];
    },

    myCommitmentsBubbleLabelText: (state) => (defaultStatus) => {
      // Initialize the upcoming commitments count...
      if (state.upcomingCommitmentsBubbleCount < 0) {
        state.upcomingCommitmentsBubbleCount = 0;
        if (defaultStatus) {
          state.upcomingCommitmentsBubbleCount = parseInt(defaultStatus);
        }
      }

      if (state.upcomingCommitmentsBubbleCount <= 0) { return ''; }
      return state.upcomingCommitmentsBubbleCount + ' upcoming';
    },

    homeCachedLookup (state) {
      if (!state.cachedLookup.home) { Vue.set(state.cachedLookup, 'home', {}); }
      return state.cachedLookup.home;
    },

    userCachedLookup (state) {
      if (!state.cachedLookup.user) { Vue.set(state.cachedLookup, 'user', {}); }
      return state.cachedLookup.user;
    },

    getOpportunityList: (state, getters) => (lookup = null) => {
      if (!lookup) { lookup = getters.userCachedLookup; }
      if ('opportunityList' in lookup && Array.isArray(lookup.opportunityList)) { return lookup.opportunityList; }
      return [];
    },
    getOpportunityTimeslots: (state, getters) => (oppID, lookup = null) => {
      if (!lookup) { lookup = getters.userCachedLookup; }
      var timeslots = _lookup(lookup, 'opportunityTimeslots', oppID.toString());
      return Array.isArray(timeslots) ? timeslots : [];
    },
    getOngoingOpportunityList: (state, getters) => (lookup = null) => {
      if (!lookup) { lookup = getters.userCachedLookup; }
      if ('ongoingOpportunityList' in lookup && Array.isArray(lookup.ongoingOpportunityList)) {
        return lookup.ongoingOpportunityList;
      }
      return [];
    },
    getOngoingOpportunityCount: (state, getters) => (lookup = null) => {
      if (!lookup) { lookup = getters.userCachedLookup; }
      if ('ongoingCount' in lookup) { return lookup.ongoingCount; }
      return 0;
    },
    ongoingOpportunityLabel: function (state, getters) {
      if (state.pageConfig && state.pageConfig.ongoinglabel) {
        return state.pageConfig.ongoinglabel;
      }

      if (state.activeInstitution &&
          'ongoinglabel' in state.activeInstitution &&
          state.activeInstitution.ongoinglabel !== '') {
        return state.activeInstitution.ongoinglabel;
      }

      var validInsts = getters.validUserInstitutions;
      for (var i = 0; i < validInsts.length; i++) {
        if ('ongoinglabel' in validInsts[i] && validInsts[i].ongoinglabel !== '') {
          return validInsts[i].ongoinglabel;
        }
      }

      return '';
    },

    userOpportunityListReloadNeeded: function (state) {
      if (!state.cachedLookup.user) { return false; }
      return state.cachedLookup.user.opportunityReloadNeeded ? true : false;
    },
    userOngoingOpportunityListReloadNeeded: function (state) {
      if (!state.cachedLookup.user) { return false; }
      return state.cachedLookup.user.ongoingOpportunityReloadNeeded ? true : false;
    },

    isUserValidMemberOfInstitution: (state, getters) => (instID) => {
      instID = parseInt(instID);
      var validInstList = getters.validUserInstitutions;
      for (var i = 0; i < validInstList.length; i++) {
        if (instID === parseInt(validInstList[i].id)) { return true; }
      }
      return false;
    },
    vtoEnabled: function (state) {
      if (!state.activeInstitution) { return false; }
      if (!('vto' in state.activeInstitution)) { return false; }
      return true;
    },
    vtoBalanceAvailable: function (state, getters) {
      if (!getters.vtoEnabled) { return 0; }

      var available = parseInt(state.activeInstitution.vto.available);
      return available > 0 ? available : 0;
    },
    vtoDisabledForPastCommitments: function (state, getters) {
      if (!getters.vtoEnabled) { return 1; }
      var disabled = parseInt(state.activeInstitution.vto.disablepast);
      return disabled > 0 ? disabled : 0;
    },
    vtoStrings: function (state, getters) {
      if (!getters.vtoEnabled) { return {}; }
      return state.activeInstitution.vto.strings;
    },
    requestVTOMenuItemTitle: function (state, getters) {
      var title = getters.vtoStrings.sidebar;
      if (title) { return title; }

      var validInsts = getters.validUserInstitutions;
      for (var i = 0; i < validInsts.length; i++) {
        if ('vto' in validInsts[i]) {
          return validInsts[i].vto.strings.sidebar;
        }
      }

      return 'Request Personal VTO'; // Shouldn't ever return here...
    },
    activeInstitutionID: function (state) {
      if (state.activeInstitution && state.activeInstitution.id) {
        return state.activeInstitution.id;
      }
      return 0;
    },
    activeInstitutionColor: function (state) {
      if (state.pageConfig && state.pageConfig.bgcolor && /^[a-f\d]{6}$/i.test(state.pageConfig.bgcolor)) {
        return '#' + state.pageConfig.bgcolor;
      }
      if (state.activeInstitution && state.activeInstitution.bgcolor && /^[a-f\d]{6}$/i.test(state.activeInstitution.bgcolor)) {
        return '#' + state.activeInstitution.bgcolor;
      }
      return '#33a9d9';
    },
    activeInstitutionColorError: function (state) {
      if (state.activeInstitution && state.activeInstitution.errcolor && /^[a-f\d]{6}$/i.test(state.activeInstitution.errcolor)) {
        return '#' + state.activeInstitution.errcolor;
      }
      return '#e91e63';
    },
    activeInstitutionColorBrightness: function (state, getters) {
      return hhUtils.colorBrightness(getters.activeInstitutionColor);
    },
    colorForTextOnWhiteBackground: function (state, getters) {
      const color = getters.activeInstitutionColor;
      const brightness = getters.activeInstitutionColorBrightness;
      let shade = 0;
      if (brightness > 210) { shade = -0.70; }
      else if (brightness > 165) { shade = -0.55; }
      else if (brightness > 120) { shade = -0.40; }
      else if (brightness > 75) { shade = -0.25; }
      else if (brightness > 30) { shade = -0.10; }

      return shade ? hhUtils.shadeColor(color, shade) : color;
    },
    colorForBackgroundWithWhiteText: function (state, getters) {
      const color = getters.activeInstitutionColor;
      const brightness = getters.activeInstitutionColorBrightness;
      let shade = 0;
      if (brightness > 210) { shade = -0.40; }
      else if (brightness > 145) { shade = -0.20; }
      else if (brightness < 75) { shade = 0.20; }

      return shade ? hhUtils.shadeColor(color, shade) : color;
    },
    // What seems to look best is the tabsBackgroundColorClass so we return that color...
    colorForSubmitButtonWithWhiteText: function (state, getters) {
      const brightness = getters.activeInstitutionColorBrightness;
      if (brightness > 210) { return 'rgba(0, 0, 0, 0.4)'; }
      else if (brightness < 70) { return 'rgba(255, 255, 255, 0.3)'; }
      return 'rgba(0, 0, 0, 0.2)';
    },
    colorForTextOnAppBackground: function (state, getters) {
      return getters.activeInstitutionColorBrightness > 180 ? hhUtils.shadeColor(getters.activeInstitutionColor, -0.70) : '#ffffff';
    },
    quasarColorForTextOnAppBackground: function (state, getters) {
      return getters.activeInstitutionColorBrightness > 180 ? 'black' : 'white';
    },
    quasarColorForToggle: function (state, getters) {
      return hhUtils.isColorKindOfGray(getters.activeInstitutionColor) ? 'green' : 'primary';
    },
    tabsBackgroundColorClass: function (state, getters) {
      if (getters.activeInstitutionColorBrightness > 210) { return 'bg-darken2'; }
      return getters.activeInstitutionColorBrightness < 70 ? 'bg-lighten' : 'bg-darken';
    },
    homeItemMenuButtonBackgroundImage: function (state, getters) {
      if (getters.activeInstitutionColorBrightness > 210) {
        return 'linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3))';
      }
      if (getters.activeInstitutionColorBrightness < 50) {
        return 'linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2))';
      }
      return 'linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1))';
    },
    sidebarBackgroundURL: function (state) {
      if (state.activeInstitution && state.activeInstitution.menubgurl) {
        return state.activeInstitution.menubgurl;
      }
      return 'https://static.helperhelper.com/app/sidebar-default.jpg';
    },
    aboutScreenTitle: function (state) {
      if (state.activeInstitution &&
          'about' in state.activeInstitution &&
          'title' in state.activeInstitution.about &&
          state.activeInstitution.about.title !== '') {
        return state.activeInstitution.about.title;
      }
      return 'About Helper Helper';
    },
    aboutScreenContent: function (state) {
      if (state.activeInstitution &&
          'about' in state.activeInstitution &&
          'content' in state.activeInstitution.about &&
          state.activeInstitution.about.content !== '') {
        return state.activeInstitution.about.content;
      }
      return 'Helper Helper is a community service app and platform that connects students to meaningful volunteer opportunities in their communities.\n\nThe Helper Helper app gives students the ability to view volunteer opportunities and independently commit to events. Through the Helper Helper app, students can also track their individual and team volunteering progress.';
    },
    isServicePortfolioEnabled: function (state) {
      if (!state.user || !state.user.options) { return false; }
      return state.user.options.portfolio > 0 ? true : false;
    },
    requiredUserProfileInstitutionID: function (state, getters) {
      if (!state.user || !state.user.id) { return 0; }

      var userInstitutions = getters.validUserInstitutions;
      for (var i = 0; i < userInstitutions.length; i++) {
        var userInst = userInstitutions[i];
        if ('profile' in userInst && 'status' in userInst.profile && userInst.profile.status === 'required') {
          return userInst.id;
        }
      }

      return 0;
    },

    getPublicInstitutionCachedState: (state) => (id) => {
      var cacheID = 'inst' + id.toString();
      if (!(cacheID in state.cachedLookup)) { Vue.set(state.cachedLookup, cacheID, {}); }
      return state.cachedLookup[cacheID];
    }
  },
  mutations: {
    setPageConfig (state, config) { Vue.set(state, 'pageConfig', config); },
    clearPageConfig (state) { Vue.set(state, 'pageConfig', null); },

    setAttemptingLogin (state, attempting) {
      state.attemptingLogin = attempting;
    },

    setUser (state, user) { state.user = user; },
    login (state, user) {
      if (user) {
        state.user = user;
        state.activeInstitution = null;

        var prefInstitutionID = Cookies.get('hhprefActiveInstitution');
        var firstValidInstitution = null;
        for (var i = 0; i < state.user.institutions.length; i++) {
          var inst = state.user.institutions[i];
          if (!inst.valid) { continue; }
          if (parseInt(inst.id) === parseInt(prefInstitutionID)) { state.activeInstitution = inst; break; }
          if (!firstValidInstitution) { firstValidInstitution = inst; }
        }

        if (!state.activeInstitution && firstValidInstitution) {
          state.activeInstitution = firstValidInstitution;
        }
      }
    },

    logout (state) {
      // Reset the state to a logged out blank copy...
      // We must copy the blankState object or it will be used by reference and we need it later...
      var blankStateCopy = JSON.parse(JSON.stringify(blankState))
      for (var key in blankStateCopy) {
        Vue.set(state, key, blankStateCopy[key]);
      }
    },

    cacheOpportunityDetails (state, payload) { _cacheOpportunityDetails(state.cachedLookup, payload); },
    setOpportunityTimeslots (state, payload) {
      if (!state.cachedLookup.user) { Vue.set(state.cachedLookup, 'user', {}); }

      _setOpportunityTimeslots(state.cachedLookup.user, payload.opportunity.id,
        payload.timeslots.map(function (slot) { return slot.id; }));
    },

    setLoadingCountryPhoneInfo (state, payload) { state.isLoadingCountryPhoneInfo = payload; },
    setCountryPhoneInfo (state, payload) { Vue.set(state.cachedLookup, 'countryPhoneInfo', payload); },

    // This will delete the commitment from all areas in which it may appear...
    deleteCommitment (state, commitID) {
      var cid = commitID.toString();
      if (cid in state.cachedLookup.commitmentDetails) {
        var commitment = state.cachedLookup.commitmentDetails[cid];
        if (hhUtils.commitmentIsUpcoming(commitment) && state.upcomingCommitmentsBubbleCount > 0) {
          state.upcomingCommitmentsBubbleCount--;
        }

        Vue.delete(state.cachedLookup.commitmentDetails, cid);
      }

      // Delete the commitment from other lookup areas of app...
      for (var lookup in state.cachedLookup) {
        if (state.cachedLookup[lookup].commitmentDetails) {
          if (cid in state.cachedLookup[lookup].commitmentDetails) {
            Vue.delete(state.cachedLookup[lookup].commitmentDetails, cid);
          }
        }
      }
    },

    upcomingCommitmentAdded (state) {
      if (state.upcomingCommitmentsBubbleCount >= 0) {
        state.upcomingCommitmentsBubbleCount++;
      }
    },

    setUpcomingCommitmentsBubbleCount (state, count) {
      state.upcomingCommitmentsBubbleCount = count;
    },

    setPublicInstitutionCachedState (state, payload) {
      if (!('institution' in payload)) { return; }

      var cacheID = 'inst' + payload.institution.id.toString();
      if (!(cacheID in state.cachedLookup)) { Vue.set(state.cachedLookup, cacheID, {}); }

      var instState = state.cachedLookup[cacheID];

      Vue.set(instState, 'about', payload.institution);
      if ('ongoingcount' in payload) { Vue.set(instState, 'ongoingCount', payload.ongoingcount); }

      _cacheOpportunityDetails(state.cachedLookup, payload);
      if ('opportunities' in payload && payload.opportunities.length > 0) {
        if (payload.opportunities[0].isongoing) {
          _setOngoingOpportunityList(instState, payload.opportunities);
        }
        else {
          _setOpportunityList(instState, payload);
        }
      }
      else if (payload.opportunity && payload.timeslots) {
        _setOpportunityTimeslots(instState, payload.opportunity.id,
          payload.timeslots.map(function (slot) { return slot.id }));
      }

      if ('teams' in payload) { Vue.set(instState, 'teams', payload.teams); }
      else if (!instState.teams) { Vue.set(instState, 'teams', []); }
    },

    setHomeOpportunityList (state, payload) {
      // We set our own find opportunity list and commitment cache for the home screen, since some commitments may
      // be missing all timeslots for the opportunities that were pulled. This will also allow the home screen to
      // stay the same when we move back from find opportunities...
      Vue.set(state.cachedLookup, 'home', {});
      _cacheOpportunityDetails(state.cachedLookup, payload);
      _setOpportunityList(state.cachedLookup.home, payload);

      Vue.set(state.cachedLookup.home, 'commitmentList', []);
      if (payload.commitments) {
        for (var i = 0; i < payload.commitments.length; i++) {
          state.cachedLookup.home.commitmentList.push(payload.commitments[i].id);
        }
      }
    },

    setUserOpportunityList (state, payload) {
      if (!state.cachedLookup.user) { Vue.set(state.cachedLookup, 'user', {}); }

      if ('ongoingcount' in payload) {
        Vue.set(state.cachedLookup.user, 'ongoingCount', parseInt(payload.ongoingcount));
      }

      _cacheOpportunityDetails(state.cachedLookup, payload);
      _setOpportunityList(state.cachedLookup.user, payload);

      if (state.cachedLookup.user.opportunityReloadNeeded) {
        state.cachedLookup.user.opportunityReloadNeeded = false;
      }
    },

    setUserOngoingOpportunityList (state, opplist) {
      if (!state.cachedLookup.user) { Vue.set(state.cachedLookup, 'user', {}); }

      _cacheOpportunityDetails(state.cachedLookup, { opportunities: opplist });
      _setOngoingOpportunityList(state.cachedLookup.user, opplist);

      if (state.cachedLookup.user.ongoingOpportunityReloadNeeded) {
        state.cachedLookup.user.ongoingOpportunityReloadNeeded = false;
      }
    },

    setUserOpportunityListReloadNeeded (state) {
      if (!state.cachedLookup.user) { return; }
      Vue.set(state.cachedLookup.user, 'opportunityReloadNeeded', true);
      Vue.set(state.cachedLookup.user, 'ongoingOpportunityReloadNeeded', true);
    },

    updateUserInstitutionSurveysAvailable (state, surveys) {
      Vue.set(state.user, 'surveys', surveys);
    },

    cacheSurveyDetails (state, payload) {
      if (!payload.surveys) { return; }

      if (!state.cachedLookup.surveyDetails) { Vue.set(state.cachedLookup, 'surveyDetails', {}); }

      var i, surveyID;
      for (i = 0; i < payload.surveys.length; i++) {
        surveyID = payload.surveys[i].id.toString();

        // If any responses are already loaded for this survey, then add to the cache...
        var cachedResponses = {};
        if (surveyID in state.cachedLookup.surveyDetails) {
          if (state.cachedLookup.surveyDetails[surveyID].responses) {
            cachedResponses = state.cachedLookup.surveyDetails[surveyID].responses.reduce(function (obj, item) {
              obj[item.id.toString()] = item; return obj;
            }, {});
          }
        }

        // Overwrite any cached survey responses or add new ones from this payload response...
        if (payload.surveys[i].responses && payload.surveys[i].responses.length) {
          cachedResponses = payload.surveys[i].responses.reduce(function (obj, item) {
            obj[item.id.toString()] = item; return obj;
          }, cachedResponses);
        }

        payload.surveys[i].responses = Object.values(cachedResponses);
        Vue.set(state.cachedLookup.surveyDetails, surveyID, payload.surveys[i]);
      }
    },

    updateUserGoals (state, userGoals) {
      if (!state.user) { return; }
      Vue.set(state.user, 'goals', userGoals);
    },

    setActiveInstitution (state, institution) {
      state.activeInstitution = institution;
      if (institution) {
        Cookies.set('hhprefActiveInstitution', institution.id, { expires: 365, path: '/', secure: true });
      }
    },

    setUserPhoneNumber (state, phone) {
      state.user.phone = phone;
    },

    setUserNotifications (state, notifications) {
      state.user.notifications = notifications;
    },

    setNextToast (state, toast) {
      state.nextToast = toast;
    },

    pushBackRoute (state, path) {
      state.backRoutes.push(path);
    },

    popBackRoute (state) {
      state.backRoutes.pop();
    },

    clearBackRoutes (state) {
      state.backRoutes = [];
    },

    updateInstitutionVTOAvailable (state, instVTO) {
      if (!state.user || !state.user.institutions) { return; }

      var instID = parseInt(instVTO.id);
      for (let i = 0; i < state.user.institutions.length; i++) {
        if (parseInt(state.user.institutions[i].id) === instID) {
          if ('vto' in state.user.institutions[i]) {
            state.user.institutions[i].vto.available = parseInt(instVTO.available);
          }
          return;
        }
      }
    },

    setUserProfileInstitutionStatus (state, payload) {
      var instID = payload.id;
      var instProfileStatus = payload.status;
      var instProfileResponseID = 'response' in payload ? payload.response : -1;

      if (!state.user || !state.user.institutions || !state.user.institutions.length) { return; }
      for (var i = 0; i < state.user.institutions.length; i++) {
        if (parseInt(state.user.institutions[i].id) === parseInt(instID)) {
          if ('profile' in state.user.institutions[i]) {
            state.user.institutions[i].profile.status = instProfileStatus;
            if (instProfileResponseID >= 0) {
              state.user.institutions[i].profile.response = instProfileResponseID;
            }
          }
          return;
        }
      }
    }
  },
  actions: {
    setActiveInstitution (context, institutionID) {
      var userInstitutions = context.getters.userInstitutions;
      for (let i = 0; i < userInstitutions.length; i++) {
        if (parseInt(userInstitutions[i].id) === parseInt(institutionID)) {
          context.commit('setActiveInstitution', userInstitutions[i]);
          break;
        }
      }
    }
  }
})

function _lookup (cache, key, id) {
  if (cache && cache[key] && cache[key][id]) { return cache[key][id]; }
  return null;
}

function _cacheOpportunityDetails (state, payload) {
  var opportunities = [];
  if ('opportunities' in payload && Array.isArray(payload.opportunities)) { opportunities = payload.opportunities; }
  else if ('opportunity' in payload) { opportunities.push(payload.opportunity); }

  if (!state.opportunityDetails) { Vue.set(state, 'opportunityDetails', {}); }
  for (var i = 0; i < opportunities.length; i++) {
    const opp = opportunities[i];
    const oppID = opp.id.toString();
    Vue.set(state.opportunityDetails, oppID, opp);
  }

  var timeslots = [];
  if ('timeslots' in payload && Array.isArray(payload.timeslots)) { timeslots = payload.timeslots; }
  else if ('timeslot' in payload) { timeslots.push(payload.timeslot); }

  if (!state.timeslotDetails) { Vue.set(state, 'timeslotDetails', {}); }
  for (i = 0; i < timeslots.length; i++) {
    const slotID = timeslots[i].id.toString();
    Vue.set(state.timeslotDetails, slotID, timeslots[i]);
  }

  // Zero or more commitments may be passed in...
  var commitments = [];
  if ('commitments' in payload && Array.isArray(payload.commitments)) { commitments = payload.commitments; }
  else if ('commitment' in payload) { commitments.push(payload.commitment); }

  if (!state.commitmentDetails) { Vue.set(state, 'commitmentDetails', {}); }
  for (i = 0; i < commitments.length; i++) {
    const commitmentID = commitments[i].id.toString();
    Vue.set(state.commitmentDetails, commitmentID, commitments[i]);
  }
}

function _setOpportunityList (state, payload) {
  Vue.set(state, 'opportunityList', []);
  if (payload && 'timeslots' in payload) {

    const opportunitySlots = {};
    for (let i = 0; i < payload.timeslots.length; i++) {
      const slot = payload.timeslots[i];
      const oppid = slot.opportunityid.toString();

      if (!opportunitySlots[oppid]) {
        state.opportunityList.push(oppid);
        opportunitySlots[oppid] = [];
      }

      opportunitySlots[oppid].push(slot.id.toString());
    }

    for (const oppID in opportunitySlots) {
      _setOpportunityTimeslots(state, oppID, opportunitySlots[oppID]);
    }
  }
}

function _setOngoingOpportunityList (state, opplist) {
  Vue.set(state, 'ongoingOpportunityList', []);
  for (let i = 0; i < opplist.length; i++) {
    state.ongoingOpportunityList.push(opplist[i].id);
  }
}

function _setOpportunityTimeslots (state, oppID, slotIDs) {
  if (!state.opportunityTimeslots) { Vue.set(state, 'opportunityTimeslots', {}); }
  oppID = oppID.toString();
  Vue.set(state.opportunityTimeslots, oppID, slotIDs);
}

export default store
