import { date } from 'quasar'

export default {
  computed: {
    // If a time zone string is sent with the opportunity, we should display date and times in that time zone...
    opportunityTimezone () {
      if (this.opportunity && 'timezone' in this.opportunity && this.opportunity.timezone) {
        // If the browser is going to choke on the opportunity time zone name (like IE 11 does), then we will just
        // skip using the time zone and display it like we used to...
        try {
          (new Date()).toLocaleString('en-US',
            { day: '2-digit', timeZoneName: 'long', timeZone: this.opportunity.timezone }).substring(4);

          // If the above executes ok, then we can use this time zone...
          return this.opportunity.timezone;
        }
        catch (err) { }
      }

      return null;
    },

    opportunityTimezoneName () {
      if (!this.opportunityTimezone) { return ''; }

      // 'longGeneric' support is more recent in browsers, so test for it and fall back...
      try {
        return (new Date()).toLocaleString('en-US',
          { day: '2-digit', timeZoneName: 'longGeneric', timeZone: this.opportunityTimezone }).substring(4);
      }
      catch (err) { }

      return (new Date()).toLocaleString('en-US',
        { day: '2-digit', timeZoneName: 'long', timeZone: this.opportunityTimezone }).substring(4);
    }
  },

  methods: {
    localeOptionsWithTimezone (options) {
      // If this opportunity is supposed to be shown in a specific time zone, return parameter for toLocaleString()
      if (this.opportunityTimezone) { options.timeZone = this.opportunityTimezone; }
      return options;
    },

    // We use this method when a date object is going to be passed to an input field. If it is just being used to
    // compare relative times, or to display as text, we can create a new Date() and use toLocateString().
    opportunityDateFromAPI (apiString) {
      let oppDate = new Date(apiString);
      if (this.opportunityTimezone) {
        const year = oppDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ year: 'numeric' }));
        const month = oppDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ month: '2-digit' }));
        const day = oppDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ day: '2-digit' }));
        const time = oppDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }));

        oppDate = new Date(year + '-' + month + '-' + day + ' ' + time);
      }
      return oppDate;
    },

    apiDateStringFromOpportunity (oppDate) {
      oppDate.setSeconds(0);
      oppDate.setMilliseconds(0);

      if (this.opportunityTimezone) {
        return date.formatDate(oppDate.getTime(), 'YYYY-MM-DD HH:mm:ss');
      }
      return oppDate.toISOString();
    },

    // This is used to show if we need to have a +n superscript when a time slot rolls into the next day.
    // We use UTC time zone to avoid any daylight savings time issues.
    countDayDifference (startDate, endDate) {
      var start, end;

      // If a time zone is passed in, then we calculate the dates in the time zone we will display this date in...
      if (this.opportunityTimezone) {
        start = Date.UTC(
          parseInt(startDate.toLocaleString('en-US', { year: 'numeric', timeZone: this.opportunityTimezone })),
          parseInt(startDate.toLocaleString('en-US', { month: 'numeric', timeZone: this.opportunityTimezone })) - 1,
          parseInt(startDate.toLocaleString('en-US', { day: 'numeric', timeZone: this.opportunityTimezone }))
        );

        end = Date.UTC(
          parseInt(endDate.toLocaleString('en-US', { year: 'numeric', timeZone: this.opportunityTimezone })),
          parseInt(endDate.toLocaleString('en-US', { month: 'numeric', timeZone: this.opportunityTimezone })) - 1,
          parseInt(endDate.toLocaleString('en-US', { day: 'numeric', timeZone: this.opportunityTimezone }))
        );
      }

      // Otherwise, we will just be using local dates and times...
      else {
        start = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        end = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
      }

      return Math.floor((end - start) / 86400000);
    },

    optionsForTimeslots (slots) {
      var showStartDay = false;
      if (slots.length > 1) {
        const first = new Date(slots[0].start);
        const last = new Date(slots[slots.length - 1].start);
        if (this.countDayDifference(first, last) > 0) { showStartDay = true; }
      }

      var options = [];
      for (let i = 0; i < slots.length; i++) {
        const slot = slots[i];
        const start = new Date(slot.start);
        const end = new Date(start.getTime() + (slot.duration * 60000));

        const dayformat = this.localeOptionsWithTimezone({ month: 'short', day: 'numeric' });
        const startday = start.toLocaleString('en-US', dayformat);
        const endday = end.toLocaleString('en-US', dayformat);

        const timeformat = this.localeOptionsWithTimezone({ hour: 'numeric', minute: '2-digit', hour12: true });
        const starttime = start.toLocaleString('en-US', timeformat);
        const endtime = end.toLocaleString('en-US', timeformat);

        const showEndDay = startday !== endday;

        let optionLabel = (showStartDay ? startday + ' at ' : '') + starttime + ' - ' + endtime;
        if (slot.name !== '') { optionLabel += ' (' + slot.name + ')'; }

        const option = { label: optionLabel, value: slot.id };
        if (showEndDay) { option.label += ' on ' + endday; }

        options.push(option);
      }

      return options;
    }

  }
};
