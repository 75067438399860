function _loadPageRoute (path, page, name, auth) {
  var route = { path: path, component: () => import('pages/' + page), name: name };
  if (auth === 'admin') { route.meta = { requiresAuth: true, requiresAdmin: true }; }
  else if (auth) { route.meta = { requiresAuth: true }; }
  return route;
}

import hhChrome from 'layouts/hhChrome'
import hhLogin from 'pages/hhLogin'
import hhHome from 'pages/hhHome'

const routes = [
  {
    path: '/',
    component: hhChrome, // don't lazy-load the layout chrome...
    children: [
      // don't lazy-load the home page...
      { path: '', component: hhHome, name: 'Home', meta: { requiresAuth: true } },

      _loadPageRoute('/opportunities', 'hhOpportunities', 'Find Opportunities', true),
      _loadPageRoute('/opportunities/ongoing', 'hhOpportunities', 'Ongoing Opportunities', true),

      _loadPageRoute('/commitments', 'hhCommitments', 'My Commitments', true),
      _loadPageRoute('/commitments/past', 'hhCommitments', 'Past Commitments', true),

      // Individual Opportunities
      _loadPageRoute('/opportunity/:id/checkin', 'hhOpportunity', 'Opportunity Check-in', true),
      _loadPageRoute('/opportunity/:id', 'hhOpportunity', 'Opportunity Details', true),
      _loadPageRoute('/commitment/:id', 'hhOpportunity', 'Commitment Details', true),

      // Past Commitments
      _loadPageRoute('/commitments/add-past', 'hhAddPastCommitment', 'Add Past Commitment (Step 1)', true),
      _loadPageRoute('/commitments/add-past/:timeslotid(\\d+)', 'hhEditPastCommitment', 'Add Past Commitment (Step 2) Timeslot', true),
      _loadPageRoute('/commitments/add-past/:date(\\d{4}-\\d{2}-\\d{2})', 'hhEditPastCommitment', 'Add Past Commitment (Step 2) Date', true),
      _loadPageRoute('/commitments/add-past/o:opportunityid(\\d+)', 'hhEditPastCommitment', 'Add Past Commitment (Step 2) Opportunity', true),
      _loadPageRoute('/commitments/edit-past/:commitmentid', 'hhEditPastCommitment', 'Edit Past Commitment', true),

      // Forms and Surveys
      _loadPageRoute('/surveys', 'hhSurveys', 'Surveys', true),
      _loadPageRoute('/surveys/responses', 'hhSurveys', 'Survey Responses', true),
      _loadPageRoute('/survey/:surveyid', 'hhSurvey', 'Survey', true),
      _loadPageRoute('/survey/:surveyid/r/:responseid', 'hhSurvey', 'Survey Response', true),

      _loadPageRoute('/admin', 'hhAdmin', 'Administrator Panel', 'admin'),
      _loadPageRoute('/admin/checkin', 'hhAdminCheckinOpportunities', 'Administrator Check-in List', 'admin'),
      _loadPageRoute('/admin/checkin/:id', 'hhAdminCheckinOpportunity', 'Event Check-in', 'admin'),
      _loadPageRoute('/admin/user-profile/:institutionid/:userid/edit', 'hhSettingsProfile', 'Edit User Profile',
        'admin'),
      _loadPageRoute('/admin/user-goal-award/:institutionid/:userid/:goalid', 'hhAdminGoalAwardProgress', 'View User Goal Progress', 'admin'),
      _loadPageRoute('/admin/team-goal-award/:institutionid/:teamid/:goalid', 'hhAdminGoalAwardProgress', 'View Team Goal Progress', 'admin'),
      _loadPageRoute('/admin/branch-goal-award/:institutionid/:branchid/:goalid', 'hhAdminGoalAwardProgress', 'View Branch Goal Progress', 'admin'),
      _loadPageRoute('/admin/survey/:surveyid/preview', 'hhSurvey', 'Admin Survey Preview', 'admin'),
      _loadPageRoute('/admin/survey/:surveyid/r/:responseid', 'hhSurvey', 'Admin Survey Response', true),
      _loadPageRoute('/admin/commitment/:id/surveys/org', 'hhAdminCommitmentSurveys', 'Org Admin Commitment Surveys', true),
      _loadPageRoute('/admin/commitment/:id/surveys', 'hhAdminCommitmentSurveys', 'Admin Commitment Surveys', true),

      // Request VTO
      _loadPageRoute('/commitments/request-vto', 'hhEditPastCommitment', 'Request Personal VTO', true),

      // Admin check-in with a "key" is allowed without being logged in...
      _loadPageRoute('/admin/checkin/:id/:key', 'hhAdminCheckinOpportunity', 'Event Check-in with Key', false),

      _loadPageRoute('/stats', 'hhStats', 'Stats and Goals', true),
      _loadPageRoute('/impacts/team/:teamid', 'hhImpacts', 'Team Impact', true),
      _loadPageRoute('/impacts/branch/:branchid', 'hhImpacts', 'Branch Impact', true),
      _loadPageRoute('/impacts', 'hhImpacts', 'Institution Impact', true),
      _loadPageRoute('/tutorial', 'hhTutorial', 'Tutorial', false),
      _loadPageRoute('/about', 'hhAbout', 'About', true),
      _loadPageRoute('/settings', 'hhSettings', 'Settings', true),
      _loadPageRoute('/settings/profile/:institutionid', 'hhSettingsProfile', 'Settings - Edit Profile', true),
      _loadPageRoute('/settings/password', 'hhSettingsPassword', 'Settings - Change Password', true),
      _loadPageRoute('/settings/phone', 'hhSettingsPhone', 'Settings - Change Phone', true),
      _loadPageRoute('/privacy-policy', 'hhPrivacyPolicy', 'Privacy Policy', false),
      _loadPageRoute('/forgot-password', 'hhForgotPassword', 'Forgot Password', false),
      _loadPageRoute('/get-password', 'hhForgotPassword', 'Get Password', false),
      { path: '/login', component: hhLogin, name: 'Log In' }, // don't lazy load login -- no auth needed

      // Public embed URLs
      _loadPageRoute('/signup/:institutionid', 'hhInstitutionSignup', 'Volunteer Sign Up', false),
      _loadPageRoute('/confirm/:institutionid', 'hhInstitutionSignupConfirm', 'Volunteer Sign Up Confirmation', false),
      _loadPageRoute('/inst/:institutionid/opportunities', 'hhOpportunities', 'Public Opportunities', false),
      _loadPageRoute('/inst/:institutionid/opportunities/ongoing', 'hhOpportunities', 'Public Ongoing Opps', false),
      _loadPageRoute('/inst/:institutionid/opportunity/:id', 'hhOpportunity', 'Public Opportunity', false),

      _loadPageRoute('/inst/:institutionid/unsubscribe', 'hhUnsubscribe', 'Unsubscribe', false),

      // Reader Reader reading minutes tracker routes...
      _loadPageRoute('/reader/:readerid', 'hhReaderSignup', 'Reading Minutes - Signup', false),
      _loadPageRoute('/reader/:readerid/u/:userid/:userkey', 'hhReaderRecord', 'Reading Minutes - Enter Data', false)
    ]
  },

  _loadPageRoute('/inst/:institutionid/calendar', 'hhCalendar', 'Opportunity Calendar', false),

  // This is an old path we used for uploading commitment photos which is now done in a modal on the commitment page
  { path: '/commitment/:id/upload', redirect: to => { return '/commitment/' + to.params.id; } }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/404.vue')
  })
}

export default routes
