<template>
  <!-- minimized is required when not in mobile layout but showing in an iframe -->
  <q-dialog v-model="showModal" no-backdrop-dismiss
    :position="useMobileLayout ? 'bottom' : 'standard'">
    <div class="hhModal" :class="{ isMobileModal: useMobileLayout }">
      <div class="hhModalTitle" :style="{ backgroundColor: modalTitleColor }">
        <hhScreenTitle style="flex: 1; padding: 10px;">{{ title }}</hhScreenTitle>
        <q-btn @click="dismissModal" :label="dismissLabel" flat :color="closeButtonColor" style="margin: 6px;" />
      </div>
      <hhBanner v-if="errorMessage !== ''" error>{{ errorMessage }}</hhBanner>
      <div class="hhModalContent" :style="{ backgroundColor: modalBackgroundColor }"><slot /></div>
    </div>
  </q-dialog>
</template>

<script>
import hhUtils from '../utils.js'
import hhBanner from '../components/hhBanner.vue'
import hhScreenTitle from '../components/hhScreenTitle.vue'

export default {
  name: 'hh-timeslot-signup-modal',

  props: {
    title: { type: String, required: true },
    value: { type: Boolean, default: false },
    dismissLabel: { type: String, default: 'Cancel' },
    preventDismiss: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' }
  },

  components: {
    hhBanner,
    hhScreenTitle
  },

  computed: {
    showModal: {
      get () { return this.value; },
      set (val) { this.$emit('input', val); }
    },

    modalTitleColor () { return hhUtils.shadeColor(this.$store.getters.activeInstitutionColor, -0.15); },
    modalBackgroundColor () { return hhUtils.shadeColor(this.$store.getters.activeInstitutionColor, -0.05); },
    closeButtonColor () { return this.$store.getters.quasarColorForTextOnAppBackground; },

    // Use this instead of this.$q.platform.is.mobile in case it is being viewed in iframe or other narrow window
    useMobileLayout () { return window.innerWidth < 540; }
  },

  methods: {
    dismissModal () {
      // hhAddCommitmentPhotos modal needs to prevent dismissing while files are uploading...
      if (this.preventDismiss) {
        this.$emit('dismiss-attempted');
        return;
      }

      this.showModal = false;
    }
  }
}
</script>

<style lang="stylus">
.hhModal
  border: 10px solid white
  min-width: 420px /* needs to be about 120px smaller than useMobileLayout trigger width due to padding */
  max-width: 420px /* if max-width different from min-width, can jitter window as inputs are focused */

  .hhModalTitle
    position: relative
    display: flex
    align-items: flex-start
    z-index: 1
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4)

  .hhModalContent
    max-height: 70vh
    overflow: auto
    padding: 1px
    padding-top: 0px

  &.isMobileModal
    min-width: 0px
    max-width: none;
    border: 0px
    border-top: 2px solid white

    > div
      border: 0px
</style>
