<template>
  <div v-if="!hidden" class="hhBanner" :class="classes" :style="style">
    <div v-if="icon" class="icon bg-info"><q-icon :name="icon" /></div>
    <div class="note"><slot /></div>
    <div v-if="dismissible" @click="hidden=true" class="icon" style="cursor: pointer;" transition-hide="slide-up"><q-icon name="close" /></div>
  </div>
</template>

<script>
export default {
  name: 'hh-banner',

  props: {
    striped: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    icon: { type: String },
    bg: { type: String, default: '' },
    dismissible: { type: Boolean, default: false }
  },

  data () {
    return {
      hidden: false
    }
  },

  computed: {
    classes () {
      const c = {};
      if (this.striped) { c.striped = true; }
      if (!this.error) {
        if (this.bg !== '') { c[this.bg] = true; }

        // If no background is specified, use the tabs background class which will lighten or darken depending
        // on the defined institution color so that the banner always looks good
        else { c[this.$store.getters.tabsBackgroundColorClass] = true; }
      }
      return c;
    },

    style () {
      if (this.error) { return { backgroundColor: this.$store.getters.activeInstitutionColorError }; }
      return {};
    }
  }
}
</script>

<style lang="stylus">
.hhBanner
  color: #fff;
  display: flex;

  &.striped
    border-top: 2px solid white;
    font-weight: bold;

  .note
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    padding: 13px

  .icon
    position: relative;
    top: -1px;
    font-size: 24px;
    line-height: 24px;
    padding: 12px

</style>
