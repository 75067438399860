<template>
  <div>
    <q-icon v-if="sash.label === 'great job'" name="stars" class="hhOpportunitySashStars" :style="{ color: sashColor }" />
    <div v-else :style="{ backgroundColor: sashColor }" class="hhOpportunitySash">
      <div>
        <q-icon :name="sash.icon" style="position: relative; top: -1px; font-weight: normal;" />
        {{ sash.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import hhUtils from '../utils'

import hhSurveysMixin from '../mixins/hhSurveysMixin'

export default {
  name: 'hh-commitment-sash',

  props: {
    opportunity: { type: Object, default: null },
    timeslot: { type: Object, default: null },
    commitment: { type: Object, default: null },
    forceMode: { type: String, default: '' }
  },

  mixins: [ hhSurveysMixin ],

  computed: {
    ...mapGetters(['activeInstitutionColor', 'userInstitutions']),

    mode () {
      if (this.forceMode) { return this.forceMode; }
      return hhUtils.commitmentMode(this.commitment, this.timeslot);
    },

    sashColor () {
      if (this.forceMode === 'full') { return '#777'; }
      if (this.forceMode === 'waitlist') { return '#777'; }

      var baseSashColor = this.$store.getters.colorForBackgroundWithWhiteText;
      if (this.sash.class === 'sashNotice') { return hhUtils.shadeColor(baseSashColor, 0.1); }
      return hhUtils.shadeColor(baseSashColor, -0.15);
    },

    sash () {
      // If the mode is validated, past, or recorded, see if there are unanswered reflection surveys
      // and show that banner, if so...
      if (this.mode === 'validated' || this.mode === 'past' || this.mode === 'recorded') {
        if (this.commitment && this.opportunity) {
          if (this.surveysCommitmentHasUnansweredReflections(this.commitment, this.opportunity)) {
            return { label: 'respond', icon: 'assignment', class: 'sashAction' };
          }
        }
      }

      if (this.mode === 'full') {
        return { label: 'full', icon: 'do_not_disturb', class: 'sashNotice' };
      }
      else if (this.mode === 'waitlist') {
        return { label: 'wait list', icon: 'list_alt', class: 'sashNotice' };
      }
      else if (this.mode === 'rejected') {
        return { label: 'rejected', icon: 'do_not_disturb', class: 'sashNotice' };
      }
      else if (this.mode === 'checkin') {
        return { label: 'check in', icon: 'assignment_turned_in', class: 'sashAction' };
      }
      else if (this.mode === 'checkout') {
        return { label: 'check out', icon: 'assignment_turned_in', class: 'sashAction' };
      }
      else if (this.mode === 'logtime') {
        return { label: 'log time', icon: 'assignment', class: 'sashAction' };
      }
      else if (this.mode === 'validate') {
        return { label: 'validate', icon: 'error', class: 'sashAction' };
      }
      else if (this.mode === 'validated') {
        return { label: 'great job', icon: 'stars', class: 'sashNotice' };
      }
      else if (this.mode === 'past') { // past commitments not attached to opportunity
        return { label: 'submitted', icon: 'check', class: 'sashNotice' };
      }
      else if (this.mode === 'recorded') { // vto requests that were recorded but not yet validated
        return { label: 'recorded', icon: 'check', class: 'sashNotice' };
      }
      else if (this.mode === 'pending') { // public opportunity sign up pending email confirmation
        return { label: 'pending', icon: 'email', class: 'sashAction' };
      }
      else if (this.mode === 'waitlisted') {
        return { label: 'waitlisted', icon: 'list_alt', class: 'sashNotice' };
      }
      else {
        return { label: 'signed up', icon: 'location_on', class: 'sashNotice' };
      }
    }
  }
}
</script>

<style lang="stylus">
.hhOpportunitySashStars
  position: absolute
  top: 9px
  right: 9px
  font-weight: normal
  font-size: 32px
  background-color: white;
  border-radius: 15px;

.hhOpportunitySash
  position: absolute;
  bottom: 25px;
  right: -50px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold
  color: white;
  -webkit-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  transform: rotate(-60deg);
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.3);
  box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.3);

  > div
    width: 154px;
    padding: 5px 0px;
    text-align: center;
</style>
