<template>
  <div class="hhGoalSummary" @click="goalClicked">
    <q-knob v-if="progressTier" v-model="progressTier.progress" class="progressKnob"
      size="40px" font-size="16px" color="goal-progress" track-color="grey-4" readonly show-value />
    <q-icon v-else name="stars" class="awardedIcon" :style="{ color: awardedIconColor }" />

    <div style="flex: 1; margin-left: 10px;">
      <b style="font-size: 1.2em;">{{ goal.name }}</b>

      <div v-if="goalTargetName != ''" style="color: #999;"><b>{{ goalTargetName }}</b></div>

      <div v-if="progressTier" class="tier">
        <div>{{ progressTier.progress > 0 ? progressTier.progress + '%' : 'No' }} progress towards <b v-if="progressTier.name !=''">{{ progressTier.name }}</b><span v-else>goal</span></div>
      </div>

      <div v-if="awardTier" class="tier">
        <q-icon v-if="progressTier" name="stars" :style="{ color: awardedIconColor }" style="font-size: 1.2em; margin-right: 2px;" />
        <div style="flex: 1;">awarded <b v-if="awardTier.name !=''">{{ awardTier.name }}</b><span v-else>goal</span> on {{ awardedOnDate }}</div>
      </div>
      <div v-if="goal.description != ''" style="margin-top: 5px;">{{ goal.description }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { date } from 'quasar';

export default {
  props: ['goal'],
  computed: {
    ...mapGetters(['activeInstitutionColor']),

    goalTargetName () {
      if (this.goal.teamid) {
        if ('branchname' in this.goal && this.goal.branchname !== '') {
          return this.goal.branchname + ' / ' + this.goal.teamname;
        }
        return this.goal.teamname;
      }

      if (this.goal.branchid) {
        return this.goal.branchname;
      }

      return '';
    },

    awardedIconColor () {
      return this.$store.getters.colorForBackgroundWithWhiteText;
    },

    progressTier () {
      for (let i = 0; i < this.goal.tiers.length; i++) {
        if (this.goal.tiers[i].progress < 100) {
          return this.goal.tiers[i];
        }
      }
      return null;
    },

    awardTier () {
      for (let i = 0; i < this.goal.tiers.length; i++) {
        if (this.goal.tiers[i].progress >= 100) {
          return this.goal.tiers[i];
        }
      }
      return null;
    },

    awardedOnDate () {
      if (this.awardTier) {
        return date.formatDate(new Date(this.awardTier.awardedon), 'MMM D, YYYY');
      }
      return '';
    }
  },

  methods: {
    goalClicked () {
      this.$q.loading.show();

      this.$hhAPI.getGoalAwardDetails(this.goal.id, this.goal.teamid, this.goal.branchid);
    }
  }
}
</script>

<style lang="stylus">
.hhGoalSummary
  display: flex;
  align-items: flex-start;
  padding: 10px;
  background-color: white;
  color: #555;
  margin-bottom: 1px;
  cursor: pointer;

  .progressKnob
    position: relative;
    top: 1px;

  .awardedIcon
    font-size: 44px;
    line-height: 44px;
    position: relative;
    top: 2px;
    width: 40px;
    height: 40px;

  .tier
    display: flex;
    align-items: flex-start;
    line-height: 20px;

  .text-goal-progress
    color: #00c851
</style>
