<template>
  <div class="hhOpportunity" :class="{ hasCoverPhoto: hasCoverPhoto, isOngoingOpportunity: isOngoingOpportunity }" @click="opportunityClicked" :style="opportunityListingCSS">
    <div v-if="hasCoverPhoto" class="coverPhotoOverlay"></div>
    <div v-if="isPinnedToTop" class="pinnedToTop"><q-icon name="push_pin" class="oppPinnedToTop" /></div>
    <div class="hhOpportunitySummaryContainer">
      <div class="hhOpportunitySummary">
        <div v-if="!isOngoingOpportunity" class="oppCal">
          <div class="oppCalWeekday">{{ weekday }}</div>
          <div class="oppCalMonth">{{ month }}</div>
          <div class="oppCalMonthDay">{{ monthday }}</div>
        </div>
        <div class="oppInfo">
          <div style="position: relative;">
            <div v-if="!isOngoingOpportunity" class="oppTime">
              <b>{{ timedisplay }}<span v-if="timedisplaysup != ''" style="margin-left: 2px; font-size: 10px; position: relative; top: -5px;">{{ timedisplaysup }}</span>{{ tzdisplay }}</b>
              <b v-if="participantCount > 0">{{ participantCount }}<q-icon name="people" /></b>
            </div>
            <div class="oppCause"><div v-if="categoryColor != ''" class="oppColor" :style="{ backgroundColor: categoryColor }"></div>{{ opportunityCauseName }}</div>
            <div class="oppName">{{ opportunity.name }}</div>
          </div>
        </div>
        <div v-if="showAccessory" class="oppAccessory"><q-icon name="keyboard_arrow_right" /></div>
      </div>

      <div v-if="coverPhotoSnippet != ''" class="hhOpportunityShortDescription">{{ coverPhotoSnippet }}</div>

      <hhCommitmentSash v-if="showSash" :forceMode="sashType"
        :commitment="sashCommitment" :timeslot="sashTimeslot" :opportunity="opportunity" />
    </div>
  </div>
</template>

<script>
import hhUtils from '../utils'

import hhOpportunityTimezoneMixin from '../mixins/hhOpportunityTimezoneMixin'

import hhCommitmentSash from './hhCommitmentSash.vue'

export default {
  props: {
    opportunity: { type: Object, required: true },
    timeslots: { type: Array, default: function () { return []; } },
    commitments: { type: Array, default: function () { return []; } },
    commitment: { type: Object, default: null }, // If this is for a single commitment row
    hideAccessory: { type: Boolean, default: false },
    hidePinToTop: { type: Boolean, default: false }
  },

  mixins: [ hhOpportunityTimezoneMixin ],

  computed: {
    isPinnedToTop () {
      if (this.hidePinToTop) { return false; }
      if (this.opportunity.listing && this.opportunity.listing.pintotop > 0) { return true; }
      return false;
    },

    hasCoverPhoto () {
      if (this.opportunity.listing && this.opportunity.listing.photo) { return true; }
      return false;
    },

    opportunityListingCSS () {
      var style = { cursor: 'select' in this.$listeners ? 'pointer' : 'auto' };

      if (this.hasCoverPhoto) {
        style.height = '195px';
        style.background = "#000 url('" + this.opportunity.listing.photo.url + "') center / cover";
      }

      return style;
    },

    coverPhotoSnippet () {
      if (!this.hasCoverPhoto || !('snippet' in this.opportunity.listing)) { return ''; }

      return this.opportunity.listing.snippet;
    },

    canEditOpportunityCommitments () {
      var validInstitutions = this.$store.getters.validUserInstitutions;
      for (var i = 0; i < validInstitutions.length; i++) {
        if (this.opportunity.institution.id === validInstitutions[i].id) { return true; }
      }
      return false;
    },

    sashCommitment () {
      const now = new Date();

      for (let i = 0; i < this.commitments.length; i++) {
        const attendance = this.commitments[i].attendance;
        if (attendance === 'rejected') { continue; }

        // Do not show a commitment sash if the opportunity does not exist in a valid
        // institution for the user. However, if it is a future event, or already
        // validated, we will show that in the sash.
        if (!this.canEditOpportunityCommitments) {
          const start = new Date(this.commitments[i].start);
          const inFuture = start > now;

          if (attendance !== 'validated' && !((attendance === 'signedup' || attendance === 'waitlisted') && inFuture)) {
            continue;
          }
        }

        return this.commitments[i];
      }

      return null;
    },

    sashTimeslot () {
      if (!this.sashCommitment || this.timeslots.length < 1) { return null; }
      var timeslotID = this.sashCommitment.timeslotid.toString();
      for (var i = 0; this.timeslots.length; i++) {
        if (timeslotID === this.timeslots[i].id.toString()) { return this.timeslots[i]; }
      }
      return null;
    },

    sashType () {
      // If there is a commitment for this opportunity, do not need to override the sash type
      if (this.sashCommitment) { return ''; }

      // If one of the timeslots has been signed up for via public signup, show that...
      for (let i = 0; i < this.timeslots.length; i++) {
        if ('_signedup' in this.timeslots[i]) { return 'pending'; }
      }

      if (this.opportunityIsFull) { return 'full'; }
      if (this.opportunityIsWaitlisted) { return 'waitlist'; }
      return '';
    },

    isOngoingOpportunity () {
      return this.timeslots.length <= 0;
      // return ('isongoing' in this.opportunity && this.opportunity.isongoing > 0);
    },

    // If the opportunity cause (org) name and opportunity name match, show the category name instead of org name
    opportunityCauseName () {
      if (this.opportunity.cause.toLowerCase() === this.opportunity.name.toLowerCase()) {
        return this.categoryName;
      }
      return this.opportunity.cause;
    },

    commitmentids () {
      var commitids = [];
      for (let i = 0; i < this.timeslots.length; i++) {
        var slot = this.timeslots[i];
        if (slot.commitmentid) { commitids.push(slot.commitmentid); }
      }
      return commitids;
    },

    participantCount () {
      var n = 0;
      for (let i = 0; i < this.timeslots.length; i++) {
        n += parseInt(this.timeslots[i].numvolunteers);
      }
      return n;
    },

    opportunityIsFull () {
      // Opportunity is never shown full if current user has a commitment to that opportunity
      if (this.commitments.length > 0) { return false; }

      // If time slots exist (not ongoing), and all time slots are full, then opportunity is full
      if (this.timeslots.length > 0) {
        for (let i = 0; i < this.timeslots.length; i++) {
          if (!hhUtils.isTimeslotFull(this.timeslots[i])) { return false; }
        }
        return true;
      }

      return false;
    },

    // This function should always be called after opportunityIsFull(), as it doesn't check for fullness...
    opportunityIsWaitlisted () {
      // Opportunity is never shown full if current user has a commitment to that opportunity
      if (this.commitments.length > 0) { return false; }

      // If time slots exist (not ongoing), and no time slots are empty, but one has a wait list, then return true...
      if (this.timeslots.length > 0) {
        let hasWaitlistSlot = false;

        for (let i = 0; i < this.timeslots.length; i++) {
          if (hhUtils.isTimeslotWaitlisted(this.timeslots[i])) {
            hasWaitlistSlot = true;
          }

          // If any slot is not waitlisted and not full, then the opportunity isn't wait-listed...
          else if (!hhUtils.isTimeslotFull(this.timeslots[i])) { return false; }
        }

        return hasWaitlistSlot;
      }

      return false;
    },

    showAccessory () {
      if (this.hideAccessory) { return false; }
      if (this.showSash) { return false; }
      return true;
    },

    showSash () {
      if (this.sashCommitment || this.sashType !== '') { return true; }
      return false;
    },

    startDate () {
      // If there is one specific commitment, and it hasn't been rejected, we will use that start date...
      if (this.commitments.length === 1 && this.commitments[0].attendance !== 'rejected') {
        if (this.commitments[0].attendance === 'recorded' && this.commitments[0].checkin) {
          return new Date(this.commitments[0].checkin);
        }
        return new Date(this.commitments[0].start);
      }

      // Otherwise, start date from timeslot will do...
      return new Date(this.timeslots[0].start);
    },

    endDate () {
      let duration = this.timeslots[0].duration;

      // If there is one specific commitment, and it hasn't been rejected, we will use that duration...
      if (this.commitmentids.length === 1 && this.commitments[0].attendance !== 'rejected') {
        duration = this.commitments[0].duration;
        if (this.commitments[0].attendance === 'validated') {
          duration = this.commitments[0].timevalidated;
        }
        else if (this.commitments[0].attendance === 'recorded' && this.commitments[0].timerecorded > 0) {
          duration = this.commitments[0].timerecorded;
        }
      }

      // Otherwise, duration from timeslot will do...
      return new Date(this.startDate.getTime() + (duration * 60000));
    },

    weekday () {
      // Time zone is not too important in the day difference comparison here, as we just want a rough idea...
      var now = new Date();
      var daysDifference = Math.floor((this.startDate - now) / 1000 / 60 / 60 / 24);

      // If this opportunity / commitment is within 90 days in the future or two weeks past,
      // or is the same year as now, we display weekday. Otherwise, display year
      if ((daysDifference >= -14 && daysDifference <= 90) || now.getFullYear() === this.startDate.getFullYear()) {
        return this.startDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ weekday: 'short' }));
      }

      return this.startDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ year: 'numeric' }));
    },

    month () {
      return this.startDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ month: 'short' }));
    },

    monthday () {
      return this.startDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ day: 'numeric' }));
    },

    timedisplay () {
      // Show the time user is signed up for, unless they are signed up multiple times for this opportunity.
      if (this.commitments.length > 0) {
        if (this.commitments.length > 1) { return 'Multiple Commitments'; }
        if (this.startDate.getTime() === this.endDate.getTime()) { return this.starttime; }
        return this.starttime + ' - ' + this.endtime;
      }

      if (this.timeslots.length > 1) {
        var lastSlot = this.timeslots[this.timeslots.length - 1];
        var lastSlotStartDate = new Date(lastSlot.start);
        var lastSlotStartMonth = lastSlotStartDate.toLocaleString('en-US',
          this.localeOptionsWithTimezone({ month: 'short' }));
        var lastSlotStartMonthDay = lastSlotStartDate.toLocaleString('en-US',
          this.localeOptionsWithTimezone({ day: 'numeric' }));

        if (this.month !== lastSlotStartMonth || this.monthday !== lastSlotStartMonthDay) {
          return 'Time Slots Thru ' + lastSlotStartMonth + ' ' + lastSlotStartMonthDay;
        }

        return 'Multiple Time Slots';
      }

      return this.starttime + ' - ' + this.endtime;
    },

    timedisplaysup () {
      // If there are multiple time slots, then we are showing 'time slots thru ...' or 'multiple time slots'
      // as the date and time in this opportunity row. In that case, do not show '+x days' in superscript
      // If there is exactly one commitment, that is the time that will be shown, so we can again show the superscript.
      if (this.commitments.length !== 1 && this.timeslots.length > 1) { return ''; }

      var numdays = this.countDayDifference(this.startDate, this.endDate);
      if (numdays > 0) { return '+' + numdays + ' day' + (numdays === 1 ? '' : 's'); }
      return '';
    },

    tzdisplay () {
      // If there are multiple time slots, then we are showing 'time slots thru ...' or 'multiple time slots'
      // as the date and time in this opportunity row. In that case, do not show '+x days' in superscript
      // If there is exactly one commitment, that is the time that will be shown, so we can again show the superscript.
      if (this.commitments.length !== 1 && this.timeslots.length > 1) { return ''; }

      // If there is no fixed time zone for the event, do not show time zone...
      if (!this.opportunityTimezone) { return ''; }

      var dateTzString = this.startDate.toLocaleTimeString('en-US',
        { timeZoneName: 'short', timeZone: this.opportunityTimezone });
      var dateTzParts = dateTzString.split(/\s+/);
      return ' ' + dateTzParts[dateTzParts.length - 1];
    },

    starttime () {
      return this.startDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ hour: 'numeric', minute: '2-digit', hour12: true }));
    },

    endtime () {
      return this.endDate.toLocaleString('en-US', this.localeOptionsWithTimezone({ hour: 'numeric', minute: '2-digit', hour12: true }));
    },

    category () {
      if (this.commitment && this.commitment.category) { return this.commitment.category; }
      else if (this.opportunity && this.opportunity.category) { return this.opportunity.category; }
      return null;
    },

    categoryName () {
      return this.category ? this.category.name : '';
    },

    categoryColor () {
      if (this.category && this.category.color !== '') { return '#' + this.category.color; }
      return '';
    }
  },

  methods: {
    opportunityClicked () { this.$emit('select'); }
  },

  components: { hhCommitmentSash }
}
</script>

<style lang="stylus">
.hhOpportunity
  position: relative
  margin: 1px 0px
  overflow: hidden

.hasCoverPhoto .coverPhotoOverlay
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: rgba(0, 0, 0, 0.5)
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0.6) 65%, rgba(0,0,0,0.75) 100%)

.pinnedToTop
  position: absolute
  top: 2px
  left: 2px
  transform: rotate(-45deg)

  .oppPinnedToTop
    font-size: 16px
    color: #555

/* enlarge pin when used with a cover photo and set a small shadow in case it's on a dark background */
.hasCoverPhoto
  .pinnedToTop
    .oppPinnedToTop
      font-size: 24px
      text-shadow: 0px 0px 2px #ffffff

.hhOpportunitySummary
  width: 100%
  display: flex
  align-items: center
  box-sizing: border-box
  background-color: white
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  font-weight: bold
  color: #555

  .oppAccessory // details icon
    font-size: 26px
    color: #aaa
    width: 30px
    flex: none

    i
      position: relative
      left: -5px

  .oppCal // calendar cell
    width: 80px
    flex: none
    padding: 10px 5px
    text-align: center

    .oppCalMonth // month
      width: 40px
      margin: 0 auto
      padding: 0px 2px
      border: 1px solid #777
      border-bottom: 0px
      background-color: #777
      color: white
      font-size: 15px
      line-height: 17px
      text-transform: uppercase

    .oppCalMonthDay // monthday
      width: 40px
      margin: 0 auto
      padding: 0px 2px
      border: 1px solid #777
      font-size: 26px
      line-height: 24px
      color: #777

  .oppInfo // opportunity cell
    flex: 1
    min-width: 0 // https://stackoverflow.com/a/66689926/116223
    padding: 0px
    padding-right: 5px

    .oppTime // time + volunteer count
      position: relative

      b
        display: inline-block

        &:first-child // time
          width: 190px

        &:nth-child(2) // volunteer count
          color: #777
          .q-icon
            position: absolute
            top: 0px
            font-size: 14px

    .oppCause // cause name
      overflow: hidden
      text-overflow: ellipsis
      font-size: 14px
      color: #999
      line-height: 18px
      text-transform: uppercase
      max-width: calc(100vw - 130px) // prevent overflow into 'validated star' area of commitment sash

      .oppColor
        display: inline-block
        width: 12px
        height: 12px
        position: relative
        top: 1px
        margin-right: 4px
        border-radius: 6px

    .oppName // opportunity name
      overflow: hidden
      text-overflow: ellipsis
      font-size: 20px
      line-height: 26px

  .oppCal .oppCalWeekday
  .oppInfo .oppTime
    font-size: 12px
    line-height: 14px
    text-transform: uppercase

.hasCoverPhoto
  .hhOpportunitySummaryContainer
    position: absolute
    bottom: 0px
    width: 100%

  .hhOpportunityShortDescription
    margin-top: -10px
    padding: 3px 20px
    font-size: 12px
    line-height: 16px // hide the third line of text
    font-weight: bold
    overflow: hidden
    text-overflow: ellipsis
    color: #e8e8e8
    display: -webkit-box !important // these four lines needed for multi-line ellipsis overflow
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    white-space: pre-wrap // allow new lines in custom snippet to show up here...
    text-shadow: 0px 0px 2px #000000
    max-height: 38px
    box-sizing: border-box

  .hhOpportunitySummary
    background: none
    color: #fff

    .oppName // opportunity name
      text-shadow: 0px 0px 4px #000000

    .oppCause // cause name
      color: #ccc
      text-shadow: 0px 0px 3px #000000

    .oppTime // time + volunteer count
      text-shadow: 0px 0px 2px #000000

      b
        &:nth-child(2) // volunteer count
          color: #e8e8e8

    .oppCal // calendar cell

      .oppCalWeekday
        color: #e8e8e8;
        text-shadow: 0px 0px 2px #000000

      .oppCalMonth // month
        background-color: #e8e8e8
        color: #555
        border: 1px solid #e8e8e8
        border-bottom: 0px

      .oppCalMonthDay // monthday
        background-color: #555
        color: #e8e8e8
        border: 1px solid #e8e8e8

    .oppAccessory // details icon
      color: #ccc
      text-shadow: 0px 0px 4px #000000

.isOngoingOpportunity
  .oppCause
    padding-top: 7px
    padding-left: 20px /* make room for pin to top icon, if needed */

  .oppName
    padding-bottom: 7px
    padding-left: 20px

  /* when ongoing opportunity cell, lower pin to top icon to line up with opportunity cause name */
  .pinnedToTop
    top: 5px
</style>
