<template>
  <hhModal :value="value" title="Goal Requirements" dismiss-label="Done" @input="setValue">
    <hhGoalAwardDetails v-if="goal" :goal="goal" />
  </hhModal>
</template>

<script>
import hhModal from './hhModal.vue'
import hhGoalAwardDetails from './hhGoalAwardDetails.vue'

export default {
  name: 'hh-goal-award-details-modal',

  data () {
    return {
      value: false,
      goal: null
    }
  },

  components: {
    hhGoalAwardDetails,
    hhModal
  },

  methods: {
    setValue (v) {
      if (!v) { this.goal = null; }
      this.value = v;
      this.$emit('input', v);
    },

    goalAwardDetailsReceived (g) {
      this.$q.loading.hide();
      this.goal = g;
      this.value = true;
    },

    apiErrorReceived (response) {
      if (response.api.command === 'goal-award-get') {
        this.$q.loading.hide();
      }
    }
  },

  created () {
    this.$hhAPI.$events.$on('goalAwardDetails', this.goalAwardDetailsReceived);
    this.$hhAPI.$events.$on('apiError', this.apiErrorReceived);
  },

  beforeDestroy () {
    this.$hhAPI.$events.$off('goalAwardDetails', this.goalAwardDetailsReceived);
    this.$hhAPI.$events.$off('apiError', this.apiErrorReceived);
  }
}
</script>

<style lang="stylus">
</style>
