<template>
  <div class="hhSmallButton" :style="{ backgroundColor: disable ? '#aaa' : $store.getters.colorForBackgroundWithWhiteText }" style="white-space: nowrap;">
    <q-btn :icon="icon" :disable="disable" @click="click" style="color: white;">
      <q-chip v-if="badge" color="red" size="md" text-color="white" dense class="hhSmallButtonBadge shadow-2">{{ badge }}</q-chip>
      <label :style="labelStyle"><slot /></label>
    </q-btn>
  </div>
</template>

<script>
export default {
  props: ['icon', 'disable', 'badge'],
  computed: {
    // If there is a label and an icon, add some spacing between the two
    labelStyle () {
      if (this.$slots.default && this.icon) { return { paddingLeft: '8px' }; }
      return {};
    }
  },
  methods: {
    click () { this.$emit('click'); }
  }
}
</script>

<style lang="stylus">
.hhSmallButton
  display: inline-block;
  cursor: pointer;

  .hhSmallButtonBadge
    position: absolute;
    top: -10px;
    right: -10px;

  label
    cursor: pointer;
</style>
