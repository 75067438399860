<template>
  <div id="q-app" v-show="!$store.state.attemptingLogin">
    <div v-if="showBackground" class="background" :style="{ height: backgroundHeight, backgroundColor: activeInstitutionColor }"><div></div></div>

    <router-view />
  </div>
</template>

<script>
import { colors } from 'quasar'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['activeInstitutionColor', 'showBackground']),

    // This ensures that the background height is reset to the size of the screen, which is different than 100vh
    // on iOS devices and may change during use of the page depending on browser chrome display
    backgroundHeight () { return this.$q.screen.height + 'px'; }
  },
  watch: {
    // Any time the institution color is updated, update the 'primary' branding...
    // This is for toggle switches, calendar selector, and other quasar controls...
    // This won't work in IE 11, but it defaults to a gray #777 color (primary)...
    activeInstitutionColor: function (val) {
      colors.setBrand('primary', this.$store.getters.colorForBackgroundWithWhiteText);
    }
  },
  methods: {
    setAnalyticsUser (user) {
      this.$ga.set('userId', user.id);
    },

    updateInstVTO (instVTO) {
      this.$store.commit('updateInstitutionVTOAvailable', instVTO);
    }
  },
  created () {
    this.$root.$on('userLoggedIn', this.setAnalyticsUser);
    this.$hhAPI.$events.$on('instVTOAvailableUpdated', this.updateInstVTO);
  },
  beforeDestroy () {
    this.$root.$off('userLoggedIn', this.setAnalyticsUser);
    this.$hhAPI.$events.$off('instVTOAvailableUpdated', this.updateInstVTO);
  }
}
</script>

<style lang="stylus">
form * {
  -webkit-transform: translate3d(0, 0, 0); // fixes an iOS 10 bug with flickering components
}

.background
  position absolute
  top 0
  left 0
  width 100%
  height 100vh // will be overriden by backgroundHeight()

  > div
    position absolute
    top 0
    left 0
    opacity 0.6
    width 100%
    height 100%
    /* background: radial-gradient(ellipse at center, rgba(255,255,255,.10) 0%, rgba(255,255,255,.10) 20%, rgba(0,0,0,0.45) 70%, rgba(0,0,0,0.8) 100%) good radial */
    /* background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.2) 25%,rgba(255,255,255,0.15) 50%,rgba(0,0,0,0.2) 75%,rgba(0,0,0,0.6) 100%) */
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.1) 25%,rgba(255,255,255,0.08) 50%,rgba(0,0,0,0.1) 75%,rgba(0,0,0,0.5) 100%), linear-gradient(to left, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 15%,rgba(255,255,255,0.08) 50%,rgba(0,0,0,0.1) 85%,rgba(0,0,0,0.3) 100%)
</style>
