import { Loading } from 'quasar'

// Returns a URL where the user should be directed, if needed... If nothing is returned, then user is allowed
// to continue to wherever to.path was pointing...
function routeRequiresRedirect (to, store, Vue) {
  // Users can always access the login screen...
  if (to.path === '/login') { return ''; }

  // If this page requires admin access, check that they have it, and return to home screen if not...
  if (to.meta && 'requiresAdmin' in to.meta && !Vue.prototype.$hhAPI.isAdminSession()) {
    return '/';
  }

  // These paths should not be short-circuited for the required institution profile below...
  if (to.path.substr(0, 6) === '/admin') { return ''; } // No admin functionality should be limited by required profile
  if (to.path === '/tutorial' || to.path === '/about' || to.path === '/privacy-policy') { return ''; }
  if (!('requiresAuth' in to.meta)) { return ''; } // public paths also do not need to enforce user profile

  // If an institution configuration requires that they complete their user profile, redirect them there unless
  // they are trying to access a page within the settings screen...
  var instID = store.getters.requiredUserProfileInstitutionID;
  if (instID > 0 && to.path.substr(0, 9) !== '/settings') {
    store.commit('clearBackRoutes');
    return '/settings/profile/' + instID + '?r=home';
  }

  return '';
}

export default ({ app, router, store, Vue }) => {
  router.beforeEach((to, from, next) => {
    // some pages have custom configs when used with public institutions, reset these when outside public institution
    if (to.path.indexOf('/inst/') !== 0) { store.commit('clearPageConfig'); }

    // This makes sure that if a user's authcode expires during their session, that they get logged out and taken
    // to the login screen, potentially then redirected to the last page they were at
    Vue.prototype.$hhAPI.$events.$off('loginAuthcodeExpired');
    Vue.prototype.$hhAPI.$events.$on('loginAuthcodeExpired', function () {
      store.commit('logout');
      var pathFragment = '?to=' + encodeURIComponent(to.fullPath);
      if (to.path === '/') { pathFragment = ''; }

      next('/login' + pathFragment);
    });

    // If the user is logged in, or wishes to access the login page, allow it
    if (store.getters.isUserLoggedIn || to.path === '/login') {
      // See if the current route request a redirect elsewhere (such as a forced user profile)
      var url = routeRequiresRedirect(to, store, Vue);
      url !== '' ? next(url) : next();
      return;
    }

    Vue.prototype.$hhAPI.$events.$on('loginRefreshFailed', function () {
      store.commit('setAttemptingLogin', false);
      Loading.hide();

      // If authentication is required, redirect to login screen
      if (to.meta && 'requiresAuth' in to.meta) {
        store.commit('clearBackRoutes');

        // When we redirect to the login screen, save the page they were hoping to reach so we can redirect there.
        var pathFragment = '?to=' + encodeURIComponent(to.fullPath);
        if (to.path === '/') { pathFragment = ''; }

        next('/login' + pathFragment);
      }

      // Otherwise, this path didn't require authentication, but we tried anyway
      else {
        next();
      }
    });

    Vue.prototype.$hhAPI.$events.$on('userLoggedIn', function (payload) {
      store.commit('setAttemptingLogin', false);
      store.commit('login', payload.user);
      store.commit('setHomeOpportunityList', payload);
      Loading.hide();

      var url = routeRequiresRedirect(to, store, Vue);
      url !== '' ? next(url) : next();
    });

    store.commit('setAttemptingLogin', true);
    Loading.show({ delay: 0 });
    Vue.prototype.$hhAPI.attemptLoginRefresh();
  });
}
