<template>
  <div class="hhCheckboxGroup">
    <div style="display: block;" v-for="(option, index) in options" :key="option.value">
      <q-checkbox v-model="models[index]" :label="option.label" @input="emitUpdate" :disable="disabled" dense class="hhOption" color="accent" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'hh-checkbox-group',
  props: {
    value: { type: Array, default: function () { return []; } },
    disabled: { type: Boolean, default: false },
    options: Array
  },
  data () {
    return {
      models: this.options.map(opt => this.value.indexOf(opt.value) >= 0)
    }
  },
  watch: {
    // respond to any changes to the reactive v-model property after data() is initialized
    value () {
      this.models = this.options.map(opt => this.value.indexOf(opt.value) >= 0);
    }
  },
  methods: {
    emitUpdate () {
      var checkedValues = [];
      for (var i = 0; i < this.options.length; i++) {
        if (!this.models[i]) { continue; }
        checkedValues.push(this.options[i].value);
      }
      this.$emit('input', checkedValues);
    }
  }
}
</script>

<style lang="stylus">
.hhCheckboxGroup
  color: #555
  font-size: 20px
  line-height: 22px
  font-weight: normal
  text-transform: none

  .hhOption
    margin-top: 8px
    align-items: flex-start

  .q-checkbox--dense
    .q-checkbox__inner
      min-width: 22px
      width: 22px
      height: 22px
</style>
