<template>
  <q-layout view="lhh Lpr lff" :class="{ leftDrawerOpen: isLeftDrawerOpen }">
    <q-btn v-show="showMenuButton" id="leftMenuButton" flat
     @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu">
      <q-icon name="menu" color="white" size="33px" />
    </q-btn>

    <div v-show="showLogo" id="logo" @click="logoClicked"
      :style="{ backgroundImage: 'url(\'' + logoURL + '\')' }"></div>

    <q-header :style="{ height: showLogo ? '110px' : 'auto' }" class="hhHeader">
      <q-btn flat @click="backClicked" id="backButton" v-if="showBackButton" aria-label="Back">
        <q-icon name="keyboard_arrow_left" color="white" />
      </q-btn>
    </q-header>

    <q-drawer v-if="showSidebar" side="left" v-model="leftDrawerOpen" :breakpoint="734" elevated class="hhDrawer">
      <div class="leftDrawerBackgroundColor" :style="{ backgroundColor: menuBackgroundColor }"></div>
      <div class="leftDrawerBackground" :style="{ backgroundImage: 'url(\'' + sidebarBackgroundURL + '\')' }"></div>

      <q-scroll-area style="margin-top: 110px; width: 100%;" :style="{ height: ($q.screen.height - 110) + 'px' }" ref="leftScroll">
        <q-list style="padding-bottom: 20px;">
          <q-item @click.native="toRoute('/', false)" class="leftSideItem" clickable>
            <q-item-section avatar><q-icon name="home" /></q-item-section>
            <q-item-section>Home</q-item-section>
          </q-item>
          <q-item @click.native="findOpportunitiesClicked" class="leftSideItem" clickable>
            <q-item-section avatar><q-icon name="thumb_up" /></q-item-section>
            <q-item-section>Find Opportunities</q-item-section>
          </q-item>
          <q-item @click.native="toRoute('/commitments', true)" class="leftSideItem" clickable>
            <q-item-section avatar><q-icon name="location_on" /></q-item-section>
            <q-item-section>My Commitments</q-item-section>
          </q-item>
          <q-item v-if="areUserSurveysEnabled" @click.native="surveysClicked" class="leftSideItem" clickable>
            <q-item-section avatar><q-icon name="assignment" /></q-item-section>
            <q-item-section>Forms and Surveys</q-item-section>
          </q-item>
          <q-item @click.native="toRoute('/stats', true)" class="leftSideItem" clickable>
            <q-item-section avatar><q-icon name="stars" /></q-item-section>
            <q-item-section>See Stats &amp; Goals</q-item-section>
          </q-item>
          <q-item v-if="showTeamImpactsMenuItem" @click.native="toRoute('/impacts', true)" class="leftSideItem" clickable>
            <q-item-section avatar><q-icon name="view_list" /></q-item-section>
            <q-item-section>Team Impacts</q-item-section>
          </q-item>
          <q-item v-if="showRequestVTOMenuItem" @click.native="toRoute('/commitments/request-vto', true)" class="leftSideItem" clickable>
            <q-item-section avatar><q-icon name="add" /></q-item-section>
            <q-item-section>{{ requestVTOMenuItemTitle }}</q-item-section>
          </q-item>
          <q-item v-if="showAddPastCommitmentMenuItem" @click.native="toRoute('/commitments/add-past', true)" class="leftSideItem" clickable>
            <q-item-section avatar><q-icon name="add" /></q-item-section>
            <q-item-section>Add Past Commitment</q-item-section>
          </q-item>
          <q-item v-if="$hhAPI.isAdminSession()" @click.native="toRoute('/admin', true)" class="leftSideItem" clickable>
            <q-item-section avatar><q-icon name="verified_user" /></q-item-section>
            <q-item-section>Administrator Panel</q-item-section>
          </q-item>
          <q-item @click.native="toRoute('/tutorial', false)" class="leftSideItemSmall" clickable>
            <q-item-section>Tutorial</q-item-section>
          </q-item>
          <q-item @click.native="toRoute('/about', true)" class="leftSideItemSmall" clickable>
            <q-item-section>{{ $store.getters.aboutScreenTitle }}</q-item-section>
          </q-item>
          <q-item @click.native="toRoute('/settings', true)" class="leftSideItemSmall" clickable>
            <q-item-section>Account Settings</q-item-section>
          </q-item>
          <q-item @click.native="toRoute('/privacy-policy', true)" class="leftSideItemSmall" clickable>
            <q-item-section>Privacy Policy</q-item-section>
          </q-item>
          <q-item @click.native="logout" class="leftSideItemSmall" clickable>
            <q-item-section>Log Out</q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>

  </q-layout>
</template>

<script>
import { openURL } from 'quasar'
import { mapGetters } from 'vuex'
import hhUtils from '../utils'

export default {
  name: 'LayoutHelperHelperApp',
  data () {
    return {
      leftDrawerOpen: this.$q.platform.is.desktop && this.$q.screen.width > 734
    }
  },
  computed: {
    ...mapGetters(['validUserInstitutions', 'showSidebar', 'sidebarBackgroundURL', 'logoURL',
      'requestVTOMenuItemTitle', 'activeInstitutionColor', 'areUserSurveysEnabled']),

    isLeftDrawerOpen () {
      return (this.leftDrawerOpen && this.showSidebar);
    },
    showBackButton () {
      // Do not show menu button if logo display turned off...
      if (!this.$store.getters.showLogo) { return false; }

      if (!this.$store.getters.showBackButton) { return false; }
      if (this.$route.path === '/login') { return false; }
      return true;
    },
    showMenuButton () {
      // Do not show menu button if the side bar cannot be shown (user not logged in)
      if (!this.showSidebar) { return false; }

      // Do not show menu button if logo display turned off...
      if (!this.$store.getters.showLogo) { return false; }

      // Do not show menu button on change password page for temporary sessions...
      if (this.$hhAPI.isTemporarySession() && this.$route.path === '/settings/password') { return false; }

      return true;
    },
    menuBackgroundColor () {
      return hhUtils.shadeColor(this.activeInstitutionColor, -0.4);
    },
    showLogo () {
      // Do not show menu button if logo display turned off...
      if (!this.$store.getters.showLogo) { return false; }

      return this.$route.path !== '/tutorial';
    },
    showRequestVTOMenuItem () {
      // If there is no valid institution, cannot request vto
      if (this.validUserInstitutions.length <= 0) { return false; }

      // Show the 'request vto' button if there are any valid institutions that have not disabled it
      for (var i = 0; i < this.validUserInstitutions.length; i++) {
        var validInst = this.validUserInstitutions[i];
        if ('vto' in validInst) { return true; }
      }

      return false;
    },
    showAddPastCommitmentMenuItem () {
      // If there is no valid institution, cannot add past commitment
      if (this.validUserInstitutions.length <= 0) { return false; }

      // Show the 'add past commitments' button if there are any valid institutions that have not disabled it
      for (var i = 0; i < this.validUserInstitutions.length; i++) {
        var validInst = this.validUserInstitutions[i];
        if (!('nopastcommits' in validInst) || !validInst.nopastcommits) {
          return true;
        }
      }

      return false;
    },
    showTeamImpactsMenuItem () {
      return this.validUserInstitutions.length > 0;
    },
    forceCollapse () {
      if (this.$route.query.collapse) {
        return this.$route.query.collapse.split(',');
      }
      return [];
    }
  },
  methods: {
    openURL,
    toRoute (path, setBackHome) {
      this.$store.commit('clearBackRoutes');
      if (setBackHome) { this.$store.commit('pushBackRoute', '/'); }
      this.$router.push(path);
    },
    backClicked () {
      this.back();
    },
    back (defaultPath, distanceBack) {
      if (!defaultPath) { defaultPath = '/'; }
      if (!distanceBack) { distanceBack = 1; }

      var paths = this.$store.state.backRoutes;
      var path = (paths.length >= distanceBack) ? paths[paths.length - distanceBack] : defaultPath;

      for (let i = 0; i < distanceBack; i++) {
        if (paths.length > 0) {
          this.$store.commit('popBackRoute');
        }
        paths = this.$store.state.backRoutes;
      }

      this.$router.replace(path);
    },
    logout () {
      this.$hhAPI.logout();
      this.$store.commit('logout');
      this.toRoute('/login', false);
    },
    logoClicked () {
      // If the user is on the login page, clicking logo goes to our main site
      if (this.$route.path === '/login') {
        window.location = 'https://www.helperhelper.com/';
        return;
      }

      if (this.$store.state.pageConfig && 'logolink' in this.$store.state.pageConfig) {
        this.toRoute(this.$store.state.pageConfig.logolink, false);
      }
      else {
        this.toRoute('/', false);
      }
    },

    // If the user clicks from the sidebar, refresh the opportunity list...
    findOpportunitiesClicked () {
      this.$store.commit('setUserOpportunityListReloadNeeded');
      this.toRoute('/opportunities', true);
    },

    surveysClicked () {
      // Fetch the most recent list of surveys available if they are clicking from the sidebar menu.
      this.$hhAPI.getInstitutionSurveysAvailable();
      this.toRoute('/surveys', true);
    }
  },
  created () {
    this.$root.$on('goBack', this.back);
    if (this.forceCollapse.indexOf('sidebar') >= 0) {
      this.leftDrawerOpen = false;
    }
  },
  beforeDestroy () {
    this.$root.$off('goBack', this.back);
  }
}
</script>

<style lang="stylus">
#leftMenuButton
  position: absolute !important
  top: 0
  left: 0
  z-index: 6010

  .q-icon
    padding: 0px 8px

#backButton
  position: absolute !important
  top: 50px
  left: 10px
  width: 60px
  min-height: 32px;
  background-color: rgba(255, 255, 255, 0.3)

#logo
  position: absolute
  z-index: 6000 /* above side panel, below modals and datetime modal as of quasar v0.16.0 */
  top: 15px
  width: calc(100% - 180px)
  left: 90px
  height: 80px
  cursor: pointer
  background-size: contain
  background-repeat: no-repeat
  background-position: 50% 50%

/* sidebar is 300px, breakpoint for sidebar is 734px */
@media (min-width: 734px)
  #logo
    width: 554px
    left: calc(90px + ((100vw - 734px) / 2))

  #leftMenuButton
    left: calc((100% - 734px) / 2)

  #backButton
    left: calc(((100vw - 734px) / 2) + 10px)

  .leftDrawerOpen
    #logo
      width: calc(100% - 480px)
      left: calc(300px + 90px)
    #leftMenuButton
      left: 0px
    #backButton
      left: 10px

@media (min-width: 1034px)
  .leftDrawerOpen
    #logo
      width: 554px
      left: calc(300px + 90px + ((100% - 1034px) / 2))
    #backButton
      left: calc(((100vw - 1034px) / 2) + 10px)

.hhHeader
  box-shadow: none
  background: rgba(0, 0, 0, 0); // ios fix

.hhDrawer
  width 320px

  .leftDrawerBackgroundColor
    position absolute
    top 0
    width 100%
    height 100%

  .leftDrawerBackground
    position absolute
    top 0
    width 100%
    height 100%
    opacity 0.6
    background-position top center
    background-size cover

  .leftSideItem
    width 280px
    padding-top 15px
    padding-bottom 15px
    margin-bottom 1px
    background-color rgba(0,0,0,0.6);
    color white

    i
      color white

  .leftSideItemSmall
    width 280px
    margin-bottom 1px
    min-height: 30px;
    background-color rgba(255, 255, 255, 0.75);
    color: #444
</style>
