<template>
  <div class="hhScreen">
    <hhScreenTitle v-if="title !== undefined && title !== ''">{{ title }}</hhScreenTitle>

    <hhBanner v-if="error" icon="error" error>{{ error }}</hhBanner>

    <slot name="header" />

    <q-pull-to-refresh v-if="shouldPullToRefresh" :handler="refresh" style="overflow: hidden;">
      <q-scroll-area style="width: 100%;" ref="mainScroll" :thumbStyle="scrollThumbStyle" :barStyle="scrollBarStyle" :visible="scrollBarAlwaysVisible ? true : undefined">
        <hhBanner v-if="toast !== null" :bg="toastBackgroundClass" :icon="toast.icon" :dismissible="true">{{ toast.html }}</hhBanner>
        <slot />
      </q-scroll-area>
    </q-pull-to-refresh>
    <template v-else>
      <q-scroll-area style="width: 100%;" ref="mainScroll" :thumbStyle="scrollThumbStyle" :barStyle="scrollBarStyle" :visible="scrollBarAlwaysVisible ? true : undefined">
        <hhBanner v-if="toast !== null" :bg="toastBackgroundClass" :icon="toast.icon" :dismissible="true">{{ toast.html }}</hhBanner>
        <slot />
      </q-scroll-area>
    </template>

    <div ref="mainFooter">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import hhScreenTitle from 'components/hhScreenTitle.vue'
import hhBanner from 'components/hhBanner.vue'

export default {
  props: ['title', 'refresh', 'error'],
  data () {
    return {
      toast: null
    }
  },
  components: {
    hhBanner,
    hhScreenTitle
  },
  watch: {
    '$q.screen.height' () { this.updateMainScrollHeight(); }
  },
  computed: {
    toastBackgroundClass () {
      if ('type' in this.toast && this.toast.type !== '') { return 'bg-' + this.toast.type; }
      return '';
    },

    scrollThumbStyle () {
      if (!this.scrollBarAlwaysVisible) { return {}; }

      return {
        right: '-10px',
        borderRadius: '5px',
        backgroundColor: this.$store.getters.colorForTextOnAppBackground,
        width: '5px',
        opacity: 0.75
      };
    },

    scrollBarStyle () {
      if (!this.scrollBarAlwaysVisible) { return {}; }

      return {
        right: '-12px',
        borderRadius: '9px',
        backgroundColor: this.$store.getters.colorForTextOnAppBackground,
        width: '9px',
        opacity: 0.2
      };
    },

    // Make an always-on scrollbar if there is room on a desktop version of the web app
    scrollBarAlwaysVisible () {
      if (this.$q.platform.is.desktop && this.$q.screen.width > 1060) {
        return true;
      }

      return false;
    },

    shouldPullToRefresh () {
      // TODO: Currently this isn't playing nice with scroll views...
      return false;
      // if (!this.refresh) { return false; }
      // return true;
    }
  },
  methods: {
    updateMainScrollHeight () {
      var context = this;
      this.$nextTick(function () {
        if (!context.$refs.mainScroll) { return; }
        var scrollTop = context.$refs.mainScroll.$el.offsetTop;
        // var scrollParent = context.$refs.mainScroll.$parent.$parent.$parent;
        // var header = scrollParent ? scrollParent.$el.querySelector('header') : null;
        // var headerHeight = header ? header.offsetHeight : 0;
        var footerHeight = context.$refs.mainFooter.offsetHeight;

        // Use window.innerHeight to solve problem with iOS where 100vh is not accurate due to browser chrome
        var windowHeight = window.innerHeight;
        context.$refs.mainScroll.$el.style.height = (windowHeight - (scrollTop + footerHeight)) + 'px';

        // old code that wasn't working well for iOS:
        // context.$refs.mainScroll.$el.style.height = 'calc(100vh - ' + (scrollTop + footerHeight) + 'px)';
      });
    }
  },
  mounted: function () {
    this.updateMainScrollHeight();

    if (this.$store.state.nextToast !== null) {
      var toast = this.$store.state.nextToast;
      this.$store.commit('setNextToast', null);
      this.toast = toast;
    }
  },

  updated: function () {
    // need this in situations where the header content changes after scroll height set
    // seems to be needed on iOS for scroll to work in edit past commitment form
    this.updateMainScrollHeight();
  }
}
</script>

<style lang="stylus">
.hhScreen
  max-width: 734px
  margin: 0 auto
</style>
