export default {
  methods: {
    // apiSurveys is an array of survey objects returned from API when there are errors in the survey response
    // surveys is a list of survey objects we send to hhSurvey
    // surveyRefs is a reference to the hhSurvey components as labeled with ref=""
    // We loop through the returned survey error objects from API and set the appropriate survey errors on each hhSurvey
    surveysPopulateErrors (apiSurveys, surveyRefs) {
      // If there are no surveys or references passed in, skip this as there is nothing to populate...
      if (!Array.isArray(apiSurveys) || !Array.isArray(surveyRefs)) { return; }

      for (let i = 0; i < apiSurveys.length; i++) {
        const surveyID = apiSurveys[i].id.toString();
        const surveyErrors = apiSurveys[i].errors;

        for (let j = 0; j < surveyRefs.length; j++) {
          if (surveyID === surveyRefs[j].getSurveyID().toString()) {
            surveyRefs[j].setErrors(surveyErrors);
            break;
          }
        }
      }
    },

    surveysNeedLoadForOpportunity (opportunity, commitments) {
      // See if there are any surveys that haven't been fully loaded...
      for (let i = 0; i < opportunity.surveys.length; i++) {
        const s = this.$store.getters.getSurvey(opportunity.surveys[i].id);
        if (!s) { return true; }
      }

      // If all surveys have been loaded, see if there are any commitment's responses that haven't been fully loaded...
      for (let i = 0; i < commitments.length; i++) {

        // If there are no survey responses attached to a commitment, skip that commitment.
        const c = commitments[i];
        if (!c.surveys) { continue; }

        // Ensure that we have loaded the survey and response for this commitment for display or editing
        for (let j = 0; j < c.surveys.length; j++) {
          const survey = this.$store.getters.getSurvey(c.surveys[j].id);
          if (!survey) { return true; }

          if (c.surveys[j].response) {
            const response = this.surveysGetSurveyResponse(survey, c.surveys[j].response);
            if (!response) { return true; }
          }
        }
      }

      return false;
    },

    surveysGetSurveyResponse (survey, responseID) {
      var response = null;
      if (!survey || !Array.isArray(survey.responses) || !responseID) { return response; }

      responseID = responseID.toString();
      for (var j = 0; j < survey.responses.length; j++) {
        if (responseID === survey.responses[j].id.toString()) {
          response = survey.responses[j];
        }
      }

      return response;
    },

    surveysExcludeCategory (category, categories) {
      if (!categories || !categories.length) { return false; }

      let found = false;
      category = category.toString();

      for (let i = 0; i < categories.length; i++) {
        if (category === categories[i].toString()) { found = true; break; }
      }

      return !found;
    },

    surveysCommitmentHasUnansweredReflections (commitment, opportunity) {
      // If we don't have the opportunity information, we don't know and assume 'no'
      if (!opportunity || !commitment) { return false; }

      // Ensure we can locate a category id
      var categoryID = null;
      if (commitment && commitment.category && commitment.category.id) { categoryID = commitment.category.id; }
      else if (opportunity && opportunity.category && opportunity.category.id) { categoryID = opportunity.category.id; }
      else { return false; }

      if (!commitment.surveys || !commitment.surveys.length) { return false; }
      if (!commitment.attendance) { return false; }

      const isTimeValidated = commitment.attendance === 'validated';
      const isTimeRecorded = isTimeValidated || commitment.attendance !== 'recorded';
      if (!isTimeRecorded || !isTimeValidated) { return false; }

      for (var i = 0; i < commitment.surveys.length; i++) {
        // If survey has already been responded to, skip it...
        var oppSurvey = commitment.surveys[i];
        if (oppSurvey.response > 0) { continue; }

        // If it is not to be shown at the 'reflection' time, skip it...
        if (!oppSurvey.when) { continue; }

        if (this.surveysExcludeCategory(categoryID, oppSurvey.categories)) { continue; }

        if (oppSurvey.when === 'after-recording' && isTimeRecorded) { return true; }
        if (oppSurvey.when === 'after-validating' && isTimeValidated) { return true; }
      }

      return false;
    }
  }
};
