<template>
  <div class="hhPhoneInput">
    <q-select v-if="countries.length" v-model="country" :options="countries" options-sanitize dense @input="emitUpdate" style="margin-right: 10px; min-width: 60px;" display-value="" :hide-dropdown-icon="country ? false : undefined">
      <template v-if="phoneCountryFlagURL" v-slot:append>
        <img :src="phoneCountryFlagURL" height="21">
      </template>
      <template v-slot:option="scope">
        <q-separator v-if="scope.opt.value == 'sep'" />
        <q-item v-else v-bind="scope.itemProps" v-on="scope.itemEvents">
          <q-item-section>
            <div style="display: flex;">
              <div style="width: 40px; text-align: right; margin-right: 10px;">{{ scope.opt.prefix }}</div>
              <div>{{ scope.opt.label }}</div>
            </div>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
    <div v-else-if="isLoadingCountryPhoneInfo" style="width: 24px; margin-right: 10px;">
      <q-inner-loading :showing="true" size="24" :style="{ color: this.activeInstitutionColor }" />
    </div>
    <q-input v-model="number" type="tel" dense hide-bottom-space :disable="disabled" maxlength="20" style="flex: 1;" @input="emitUpdate">
      <template v-if="number != ''" v-slot:append>
        <q-icon name="cancel" class="iconClear" @click.stop="clearPhoneNumber" />
      </template>
    </q-input>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'hh-phone-input',
  props: {
    value: { type: Object, default () { return { country: 'US', number: '' } } },
    defaultCountry: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },

  data () {
    return {
      country: null,
      number: '',
      defaultCountryCode: this.defaultCountry
    }
  },

  watch: {
    value () { this.setValue(); },
    countries () { this.setValue(); },

    ipCountryCode () {
      // Set the country based on the user's IP address if there is no phone number provided...
      // If a phone number is already provided, we don't want to assume anything...
      // OK if only a country is provided, as that is most likely a guess or default, and user location takes priority.
      if (!this.number && this.ipCountryCode) {
        const countryCode = this.ipCountryCode;
        const c = this.countries.find(function (country) { return country.value === countryCode });

        // Only update the country if the IP country was a valid value...
        if (c && c !== this.country) {
          this.country = c;
          this.emitUpdate();
        }
      }
    }
  },

  computed: {
    ...mapGetters(['isLoadingCountryPhoneInfo', 'countryPhoneInfo', 'activeInstitution', 'activeInstitutionColor']),

    phoneCountryFlagURL () {
      if (!this.country || !this.country.value) { return ''; }
      if (!this.countryPhoneInfo || !this.countryPhoneInfo.flagurl) { return ''; }

      return this.countryPhoneInfo.flagurl.replace('%%', this.country.value.toLowerCase());
    },

    ipCountryCode () {
      return ('iplookup' in this.countryPhoneInfo ? this.countryPhoneInfo.iplookup : '');
    },

    countries () {
      let c = 'phone' in this.countryPhoneInfo ? this.countryPhoneInfo.phone : [];
      if (!c || !c.length) { c = []; }

      const countries = c.map(function (country) {
        return { value: country.code, label: country.name, prefix: country.phone };
      });

      // Set priority countries...
      if (countries.length) {
        const context = this;
        let usFound = false;
        let countriesAdded = 0;

        // Add a q-separator between priority countries and the alphabetic list. At least one priority country
        // will be pushed onto the front of countries below...
        countries.unshift({ value: 'sep' });

        // If a default country has been passed in, also list that as a priority...
        if (this.defaultCountryCode) {
          const defCountry = countries.find(function (country) { return country.value === context.defaultCountryCode });
          if (defCountry) {
            countries.unshift(defCountry);
            if (defCountry.value === 'US') { usFound = true; }
            countriesAdded++;
          }
        }

        // If we lookup up their IP address and found a country code, add that as a priority...
        if (this.ipCountryCode) {
          const ipCountry = countries.find(function (country) { return country.value === context.ipCountryCode });
          if (ipCountry) {
            countries.unshift(ipCountry);
            if (ipCountry.value === 'US') { usFound = true; }
            countriesAdded++;
          }
        }

        // Always list USA in priority countries if it wasn't added above...
        if (!usFound) {
          const usCountry = countries.find(function (country) { return country.value === 'US' });
          if (usCountry) { countries.splice(countriesAdded, 0, usCountry); }
        }
      }

      return countries;
    }
  },

  methods: {
    setValue () {
      this.number = this.value.number;

      const countryCode = this.value.country;
      const c = this.countries.find(function (country) { return country.value === countryCode });
      if (c !== this.country) { this.country = c; }
    },

    clearPhoneNumber () {
      this.number = '';
      this.emitUpdate()
    },

    // Send the updated value back to parent
    emitUpdate () {
      this.$emit('input', { country: this.country ? this.country.value : '', number: this.number });
    }
  },

  created () {
    if (!this.countries || !this.countries.length) { this.$hhAPI.loadCountryPhoneInfo(); }
    this.setValue();

    // If no default country code is passed into this component, we see if active institution has a default...
    if (!this.defaultCountryCode && this.activeInstitution) {
      if ('phonecountry' in this.activeInstitution && this.activeInstitution.phonecountry) {
        this.defaultCountryCode = this.activeInstitution.phonecountry;
      }
    }

    // If there is a default country specified for this input, and no phone number or country already defined, set it.
    if (!this.number && !this.country && this.defaultCountryCode) {
      this.$emit('input', { country: this.defaultCountryCode, number: this.number });
    }
  }
}
</script>

<style lang="stylus">
.hhPhoneInput
  color: #555
  text-transform: none
  font-size: 24px
  color: #999
  line-height: 30px
  font-weight: normal
  display: flex
</style>
