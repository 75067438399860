<template>
  <q-btn class="hhSubmitButton" @click.prevent="clicked" :style="{ backgroundColor: $store.getters.colorForSubmitButtonWithWhiteText }"><slot /></q-btn>
</template>

<script>
export default {
  name: 'hh-submit-button',
  methods: {
    setFocused () { this.focused = true },
    setBlurred () { this.focused = false },
    clicked () { this.$emit('click') }
  }
}
</script>

<style lang="stylus">
button.hhSubmitButton
  text-transform: uppercase
  color: #ffffff
  margin: 10px 0px
  font-size: 24px
  font-weight: bold
  width: 100%
</style>
