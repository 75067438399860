var linkifyHtml = require('linkifyjs/html');

export default {
  // https://stackoverflow.com/questions/2593637/how-to-escape-regular-expression-in-javascript/18151038#18151038
  regExpEscape (s) {
    // eslint-disable-next-line
    return String(s).replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1').replace(/\x08/g, '\\x08');
  },

  // https://stackoverflow.com/questions/10556879/changing-the-1-24-hour-to-1-12-hour-for-the-gethours-method
  formatTime (date) {
    var hour = date.getHours();
    var minute = date.getMinutes();
    var ap = 'AM';
    if (hour > 11) { ap = 'PM'; }
    if (hour > 12) { hour = hour - 12; }
    if (hour === 0) { hour = 12; }
    // if (hour < 10) { hour = '0' + hour; }
    if (minute < 10) { minute = '0' + minute; }
    return hour + ':' + minute + ' ' + ap;
  },

  linkifyText (text) {
    var t = text.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
    return linkifyHtml(t);
  },

  formatPhone (phone) {
    if (phone && phone.length === 10) { return phone.substring(0, 3) + '-' + phone.substring(3, 6) + '-' + phone.substring(6); }
    return phone;
  },

  formatDuration (duration, options) {
    var style = (options && options.style ? options.style : 'short');
    var hmsep = 'h'; // style: 'tiny'
    var hmsepplural = hmsep;
    var min = 'm';
    var minplural = min;
    if (style === 'short') {
      hmsep = ' hour';
      hmsepplural = ' hours';
      min = ' minute';
      minplural = ' minutes';
    }

    // Calculate the days, minutes and hours...
    var minutes = duration % 60;
    var hours = (duration - minutes) / 60;

    // 0-pad minutes if the style is not 'short'
    if (style === 'tiny') { if (minutes < 10) { minutes = '0' + minutes; } }

    if (hours > 0 || style === 'tiny') { // tinyhm forces hours + mins
      var durationString = hours + (hours === 1 ? hmsep : hmsepplural);
      if (minutes > 0 || style === 'tiny') {
        durationString += ' ' + minutes + (minutes === 1 ? min : minplural);
      }
      return durationString;
    }

    return minutes + (minutes === 1 ? min : minplural);
  },

  commitmentMode (commitment, timeslot) {
    if (!commitment) { return 'none'; }

    // If they have already validated their time, are waitlisted, or had their commitment rejected, show that
    if (commitment.attendance === 'validated' ||
        commitment.attendance === 'waitlisted' ||
        commitment.attendance === 'rejected') {
      return commitment.attendance;
    }

    // This is when showing a custom 'past commitment' that is not validated yet
    if (commitment.ispast === 1) {
      return 'past';
    }

    var now = new Date();
    var startDate = new Date(timeslot.start);
    var fifteenMinutes = 1000 * 60 * 15;

    // If we are past 15 minutes before an event, then we no longer show 'signed up',
    // and instead prompt for them to check in, check out, log time or validate commitment...
    if (now - startDate >= -fifteenMinutes) {
      // Only need to check in and out if they have not yet recorded their attendance...
      if (commitment.attendance === 'signedup') {
        var endDate = new Date(Date.parse(timeslot.start) + (timeslot.duration * 60000));
        if (now - endDate <= fifteenMinutes) {
          if (commitment.checkin === null) {
            return 'checkin';
          }
          else if (commitment.checkout === null) {
            return 'checkout';
          }
        }

        if (commitment.checkin === null || commitment.checkout === null) {
          // If this time slot does not track time (attendance-only), then only a checkin is required to
          // record or validate their attendance.
          if (!timeslot.tracktime) { return 'checkin'; }

          // Otherwise, after the event ends, we show log time screen
          return 'logtime';
        }
      }

      // If no validation key is used for this opportunity, it should be auto-validated, but if not, return recorded
      // Also, 'recorded' is returned for vto requests that do not have automatic validation turned on
      // This will show 'recorded' sash and 'this commitment has been recorded' notice
      if (commitment.novkey || commitment.ispast > 0) {
        return 'recorded';
      }

      // Or validation screen if they have logged time
      return 'validate';
    }

    // Otherwise, it is before the event, so we note they are signed up
    return 'signedup';
  },

  commitmentMaxAttachFiles (opportunity, userInstitutions) {
    for (let i = 0; i < userInstitutions.length; i++) {
      const ui = userInstitutions[i];
      if (ui.id === opportunity.institution.id) {
        if (ui.attachments && ui.attachments.max > 0) { return ui.attachments.max; }
      }
    }
    return 0;
  },

  isTimeslotFull (timeslot) {
    // If user already has a commitment, it's not full to them...
    if (timeslot.commitmentid) { return false; }

    // If there is no max volunteers, there is no waiting list and never full...
    var max = parseInt(timeslot.maxvolunteers);
    if (max <= 0) { return false; }

    var hasWaitlist = 'waitlist' in timeslot && timeslot.waitlist !== '';
    var numWaitlist = hasWaitlist ? parseInt(timeslot.numwaitlist) : 0;
    var hasAdminWaitlistWithSignup = numWaitlist > 0 && timeslot.waitlist === 'admin';

    // If there is an admin waiting list and at least one person on it, then we skip the check with numvolunteers
    // and maxvolunteers, as they cannot sign up directly to the event until the admin clears the waiting list...
    // Whether the time slot is full then comes down to whether the waiting list is full.
    if (!hasAdminWaitlistWithSignup) {
      var num = parseInt(timeslot.numvolunteers);
      if (num < max) { return false; }
    }

    if (hasWaitlist) {
      max = parseInt(timeslot.maxwaitlist);
      if (max <= 0) { return false; }

      if (numWaitlist < max) { return false; }
    }

    return true;
  },

  isTimeslotWaitlisted (timeslot) {
    if (this.isTimeslotFull(timeslot)) { return false; }

    if (!('waitlist' in timeslot) || timeslot.waitlist === '') { return false; }

    var max = parseInt(timeslot.maxvolunteers);
    if (max <= 0) { return false; }

    var num = parseInt(timeslot.numvolunteers);
    if (num >= max) { return true; }

    // If there is someone on the admin waiting list, new users cannot jump the line.
    num = parseInt(timeslot.numwaitlist);
    if (timeslot.waitlist === 'admin' && num > 0) { return true; }

    return false;
  },

  // https://stackoverflow.com/a/13542669/116223
  // pass a negative percent to darken
  shadeColor (color, percent) {
    var f = parseInt(color.slice(1), 16);
    var t = percent < 0 ? 0 : 255;
    var p = percent < 0 ? percent * -1 : percent;
    var R = f >> 16;
    var G = f >> 8 & 0x00FF;
    var B = f & 0x0000FF;
    return '#' + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
  },

  // Commitment is upcoming if it ended less than buffer ms ago...
  commitmentIsUpcoming (commitment, now, buffer) {
    now = (typeof now !== 'undefined') ? now : new Date();
    buffer = (typeof buffer !== 'undefined') ? buffer : (1000 * 60 * 15); // default 15 mins
    var endDate = new Date(Date.parse(commitment.start) + (commitment.duration * 60000));
    return (now - endDate <= buffer);
  },

  // http://www.w3.org/TR/AERT#color-contrast
  // Value of brightness will be between 0 and 255
  colorBrightness: function (color) {
    var rgbComponents = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    var r = parseInt(rgbComponents[1], 16);
    var g = parseInt(rgbComponents[2], 16);
    var b = parseInt(rgbComponents[3], 16);

    return ((r * 299 + g * 587 + b * 114) / 1000);
  },

  isColorKindOfGray (color) {
    var f = parseInt(color.slice(1), 16);
    var R = f >> 16;
    var G = f >> 8 & 0x00FF;
    var B = f & 0x0000FF;

    // If all colors are within 5% of each other, it's roughly grayscale...
    return (Math.abs(R - G) < 13 && Math.abs(R - B) < 13 && Math.abs(G - B) < 13);
  }
}
