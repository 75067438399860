<template>
  <div class="hhSurveyRow">
    <q-icon name="assignment" class="surveyIcon" />

    <div style="flex: 1; margin-left: 10px;">
      <b style="font-size: 20px;">{{ survey.title }}</b>
      <div v-if="!hideDescription && survey.description" style="margin-top: 5px; white-space: pre-wrap;">{{ survey.description }}</div>
      <div v-if="response">
        submitted {{ responseSubmittedDate }}{{ responseUpdatedDate != '' ? '; ' : '' }}
        <span style="white-space: nowrap;">{{ responseUpdatedDate }}</span>
      </div>
    </div>

    <q-icon name="keyboard_arrow_right" class="surveyAccessory" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { date } from 'quasar';

export default {
  props: ['survey', 'response', 'hideDescription'],

  computed: {
    ...mapGetters(['activeInstitutionColor']),

    responseSubmittedDate () {
      if (!this.response) { return ''; }
      var timestamp = Date.parse(this.response.created);
      return date.formatDate(timestamp, 'MMM D, YYYY') + ' at ' + date.formatDate(timestamp, 'h:mma');
    },

    responseUpdatedDate () {
      if (!this.response || !this.response.updated || this.response.created === this.response.updated) { return ''; }
      var timestamp = Date.parse(this.response.updated);
      return 'updated on ' + date.formatDate(timestamp, 'MMM D, YYYY');
    }
  }
}
</script>

<style lang="stylus">
.hhSurveyRow
  display: flex;
  align-items: flex-start;
  padding: 10px;
  background-color: white;
  color: #555;
  margin-bottom: 1px;
  cursor: pointer;

  .surveyIcon
    color: #999;
    font-size: 28px;
    width: 25px;
    height: 25px;

  .surveyAccessory
    align-self: center
    font-size: 26px
    color: #aaa
    width 30px

    i
      position: relative
      left: -5px
</style>
