<template>
  <hhScreen title="Login" :error="errorMessage" class="hhLoginScreen">
    <form @submit.prevent="login">
      <hhInputField label="Email Address" type="email" v-model="email" :disabled="promptPassword" :autofocus="true" :clearable="true" :after="promptPassword ? { icon: 'edit', handler () { promptPassword = false } } : null" color="hhbrand" />
      <hhInputField v-if="promptPassword" label="Password" :autofocus="true" type="password" v-model="password" @enter="login" color="hhbrand" />
      <hhSubmitButton @click="login">{{ promptPassword ? 'Sign In' : 'Continue' }}</hhSubmitButton>
    </form>

    <router-link :to="forgotPasswordLink" class="forgotPassword">Forgot your password? Click here.</router-link>
    <router-link :to="getPasswordLink" class="forgotPassword">Need a password for the first time?</router-link>

    <hhDownloadApps style="margin-top: 40px;" />

    <div style="margin: 20px 0px; background-color: rgba(255, 255, 255, 0.8); font-size: 14px; color: #555; padding: 10px;"><a href="https://www.helperhelper.com/" style="color: #555; font-weight: bold; text-decoration: underline;">Helper Helper</a> is a community service app that connects volunteers to meaningful experiences in the community. Helper Helper simplifies the organizational efforts for volunteer coordinators and helps volunteers easily find and schedule events.</div>
  </hhScreen>
</template>

<script>

import { mapGetters } from 'vuex'
import hhScreen from './hhScreen.vue'
import hhInputField from '../components/hhInputField.vue'
import hhDownloadApps from '../components/hhDownloadApps.vue'
import hhSubmitButton from '../components/hhSubmitButton.vue'

export default {
  name: 'hh-login-page',
  components: {
    hhScreen,
    hhInputField,
    hhDownloadApps,
    hhSubmitButton
  },
  data () {
    return {
      errorMessage: '',
      email: '',
      password: '',
      promptPassword: false
    }
  },
  computed: {
    ...mapGetters(['isUserLoggedIn']),
    forgotPasswordLink () {
      let link = '/forgot-password';
      if (this.email !== '') { link += '?e=' + encodeURIComponent(this.email); }
      return link;
    },
    getPasswordLink () {
      let link = '/get-password';
      if (this.email !== '') { link += '?e=' + encodeURIComponent(this.email); }
      return link;
    }
  },
  watch: {
    isUserLoggedIn: function (val) {
      if (val) { this.redirectLoggedIn(); }
    }
  },
  created () {
    this.$hhAPI.$events.$on('userLoggedIn', this.userLoggedIn);
    this.$hhAPI.$events.$on('emailLoginDetails', this.emailLoginDetails);
    this.$hhAPI.$events.$on('apiError', this.apiError);
  },
  beforeDestroy () {
    this.$hhAPI.$events.$off('userLoggedIn', this.userLoggedIn);
    this.$hhAPI.$events.$off('emailLoginDetails', this.emailLoginDetails);
    this.$hhAPI.$events.$off('apiError', this.apiError);
  },
  methods: {
    userLoggedIn (payload) {
      this.$store.commit('login', payload.user);
      this.$store.commit('setHomeOpportunityList', payload);
      this.$q.loading.hide();
      this.redirectLoggedIn();
    },
    emailLoginDetails (redirect) {
      this.$q.loading.hide();

      // For single sign-on, we'll get a redirect to the login page...
      if (redirect && redirect !== '') {
        window.location = redirect;
        return;
      }

      // Otherwise, we prompt them for their password through Helper Helper...
      this.promptPassword = true;
    },
    redirectLoggedIn () {
      this.$store.commit('clearBackRoutes');
      if (this.$hhAPI.session && this.$hhAPI.session.temporary) {
        this.$router.replace('/settings/password');
      }
      else {
        if ('to' in this.$route.query) { this.$router.replace(this.$route.query.to); }
        else { this.$router.replace('/'); }
      }
    },
    apiError (response) {
      if (response.api.command !== 'user-login' && response.api.command !== 'user-loginemail') { return; }
      this.$q.loading.hide();
    },
    login () {
      this.errorMessage = '';
      if (this.email === '' || (this.promptPassword && this.password === '')) {
        this.errorMessage = 'Enter your ' + (this.email === '' ? 'email address' : 'password') + ' to sign in.';
        return;
      }

      // Remove focus from active input field so hitting enter multiple times doesn't cause login error popup loop
      if ('activeElement' in document) { document.activeElement.blur(); }

      this.$q.loading.show({ delay: 250 })
      if (this.promptPassword) {
        this.$hhAPI.loginWithPassword(this.email, this.password);
      }
      else {
        // We pass the desired destination to the backend. If this is a single sign-on situation, the backend
        // can then use this redirect URL as the RelayState= parameter of the SAML Request to target specific pages
        var redirect = window.location.protocol + '//' + window.location.hostname;
        if (window.location.port !== 80) { redirect += ':' + window.location.port; }
        if ('to' in this.$route.query) { redirect += this.$route.query.to; }

        this.$hhAPI.loginEmailQuery(this.email, redirect);
      }
    }
  }
}
</script>

<style lang="stylus">
.hhLoginScreen a.forgotPassword
  margin-top: 30px
  display: block
  color: white
  text-align: center
  font-weight: bold
  text-decoration: none
</style>
