<template>
  <div class="hhSelectMultiple">
    <hhCheckboxGroup v-show="selected.length > 0" v-model="selected" :disabled="disabled" :options="checkedOptions" @input="setSelected" />
    <q-select v-if="!disabled" v-show="showSelectBox" v-model="select" ref="qSelectInput" @input="setSelect" dense
      emit-value map-options options-sanitize :options="selectOptions" :label="selected.length ? '[ you may select another ]' : ''" />
  </div>
</template>

<script>
import hhCheckboxGroup from './hhCheckboxGroup.vue'

export default {
  name: 'hh-select-multiple',
  props: {
    value: { type: Array, default: function () { return []; } },
    disabled: { type: Boolean, default: false },
    options: Array,
    max: { type: Number, default: 0 }
  },
  components: {
    hhCheckboxGroup
  },
  data () {
    return {
      selected: this.value,
      select: ''
    }
  },
  computed: {
    showSelectBox () {
      if (this.selectOptions.length <= 0) { return false; }
      if (this.max > 0 && this.max <= this.selected.length) { return false; }
      return true;
    },

    checkedOptions () {
      var checked = [];
      for (var i = 0; i < this.options.length; i++) {
        if (this.selected.indexOf(this.options[i].value) >= 0) {
          checked.push(this.options[i]);
        }
      }
      return checked;
    },
    selectOptions () {
      var opts = [];
      for (var i = 0; i < this.options.length; i++) {
        var opt = this.options[i];
        if (this.selected.indexOf(opt.value) < 0) {
          opts.push(opt);
        }
      }
      return opts;
    }
  },
  watch: {
    // respond to any changes to the reactive v-model property after data() is initialized
    value () {
      this.selected = this.value;
    }
  },
  methods: {
    setSelected (val) {
      this.selected = val;
      this.emitUpdate();
    },

    setSelect (val) {
      this.selected.push(val);
      this.select = '';
      this.$refs.qSelectInput.blur();
      this.emitUpdate();
    },

    emitUpdate () {
      this.$emit('input', this.selected);
    }
  }
}
</script>
