<template>
  <div class="hhFileUpload" :class="{ isDisabled: disabled }">
    <div v-for="(file, i) in selected" :key="i" class="file">
      <div v-if="file.key == ''" class="file-spinner"><q-inner-loading :showing="true" size="20px" /></div>
      <q-icon v-else name="check_circle" />
      <q-linear-progress v-if="file.key == ''" :value="file.progress" class="file-progress" style="width: 50px; height: 13px;" />
      <a v-if="file.url" :href="file.url" class="file-link">{{ file.name }}</a>
      <div v-else class="file-link">{{ file.name }}</div>
      <div class="file-size">{{ prettyFileSize(file.size) }}</div>
      <q-icon v-if="canDeleteFile(i)" name="delete_forever" class="file-delete" @click.native="deleteFile(i)" />
    </div>

    <div class="hhFileUploadInput" v-if="canAddFile">
      <q-icon name="attach_file" style="position: relative; top: -1px; padding-right: 5px;" /> <input type="file" ref="addFiles" @change="filesSelected" :multiple="canAddMultiple" :accept="accept" />
    </div>

    <div v-if="errors.length > 0" class="fileErrors" style="color: rgb(233, 30, 99)">
      The following file{{ errors.length == 1 ? '' : 's' }} could not be uploaded. Please try again. <q-icon name="cancel" @click.native="clearFileErrors" style="font-size: 17px; position: relative; top: -1px;" />
      <div v-for="(file, i) in errors" :key="i">
        <div>{{ file.error }}: <span style="color: #555;">{{ file.name }}</span></div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'hh-file-upload',
  props: {
    files: { type: Array, default: function () { return []; } },
    maxfiles: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    preview: { type: Boolean, default: false },
    nodelete: { type: Boolean, default: false },
    accept: String,
    apiData: Object
  },
  data () {
    return {
      selected: this.files,
      errors: [],
      nextFileID: 1
    }
  },
  computed: {
    canAddFile () {
      if (this.disabled) { return false; }
      return (this.maxfiles <= 0 || this.maxfiles > this.selected.length);
    },
    canAddMultiple () {
      if (this.maxfiles <= 0) { return true; } // unlimited uploads...
      if ((this.maxfiles - this.selected.length) > 1) { return true; }
      return false;
    }
  },
  methods: {
    prettyFileSize (size) {
      var amt = 'B';
      if (size > 1000) { size = size / 1000; amt = 'KB'; }
      if (size > 1000) { size = size / 1000; amt = 'MB'; }
      if (size > 1000) { size = size / 1000; amt = 'GB'; }
      if (size < 100) {
        return (Math.round(size * 10) / 10) + ' ' + amt;
      }
      return Math.round(size) + ' ' + amt;
    },

    canDeleteFile (fileIndex) {
      if (this.disabled) { return false; }

      // Even if 'nodelete' flag is passed in, allow them to cancel the upload...
      var file = this.selected[fileIndex];
      if (file && file.cancelToken) { return true; }

      if (this.nodelete) { return false; }

      return true;
    },

    // Delete the file from question response, and if it is in-progress, cancel it.
    deleteFile (fileIndex) {
      var file = (this.selected.splice(fileIndex, 1))[0];
      if (file.cancelToken) { file.cancelToken.cancel(); file.cancelToken = null; }
      this.emitUpdate();
    },

    clearFileErrors () { this.errors = []; },

    filesSelected () {
      this.clearFileErrors();

      var filelist = this.$refs.addFiles.files;
      for (var i = 0; i < filelist.length; i++) {
        var file = filelist[i];

        // Our server will not accept files larger than 32 MB...
        if (file.size > 32000000) {
          this.errors.push({ name: file.name, size: file.size, error: 'File too large' });
          continue;
        }

        var fileID = 'hhfileupload-' + this._uid + '-file' + this.nextFileID;
        var progress = this.preview ? 1 : 0;
        var key = this.preview ? 'abcdefgh' : '';
        this.selected.push({ id: fileID, name: file.name, size: file.size, key: key, progress: progress });
        this.emitUpdate();

        this.nextFileID++;

        // Upload the file, unless we are in preview mode...
        if (!this.preview) {
          this.$hhAPI.uploadFileAttachment(this.apiData, file, fileID);
        }
      }

      this.$refs.addFiles.value = '';
    },

    _fileByID (fileID) {
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].id === fileID) { return this.selected[i]; }
      }
      return null;
    },

    setFileUploadCancelToken (fileID, token) {
      var file = this._fileByID(fileID);
      if (!file) { return; }

      file.cancelToken = token;
    },

    setFileUploadProgress (fileID, progress) {
      var file = this._fileByID(fileID);
      if (!file) { return; }

      file.progress = progress / 100;
    },

    fileUploadSuccess (fileID, payload) {
      var file = this._fileByID(fileID);
      if (!file) { return; }

      file.key = payload.file.key;
      file.url = payload.file.url;
      delete file.cancelToken;
      this.emitUpdate();
    },

    fileUploadFailure (fileID, error) {
      // Remove any trailing spaces from error message...
      if (error.substr(-1) === '.') { error = error.substr(0, error.length - 1); }

      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].id === fileID) {
          var file = (this.selected.splice(i, 1))[0];
          file.error = error;
          delete file.cancelToken;
          this.errors.push(file);
          this.emitUpdate();
          return;
        }
      }
    },

    emitUpdate () {
      // _uploading_ will let parent component know that a file upload is in-progress...
      var selectedFiles = this.selected.map(file => file.key ? file.key : '_uploading_');
      this.$emit('input', selectedFiles);
    }
  },
  mounted () {
    this.$hhAPI.$events.$on('fileUploadCancelToken', this.setFileUploadCancelToken);
    this.$hhAPI.$events.$on('fileUploadProgress', this.setFileUploadProgress);
    this.$hhAPI.$events.$on('fileUploadError', this.fileUploadFailure);
    this.$hhAPI.$events.$on('fileUploadSuccess', this.fileUploadSuccess);
  },
  beforeDestroy () {
    this.$hhAPI.$events.$off('fileUploadCancelToken', this.setFileUploadCancelToken);
    this.$hhAPI.$events.$off('fileUploadProgress', this.setFileUploadProgress);
    this.$hhAPI.$events.$off('fileUploadError', this.fileUploadFailure);
    this.$hhAPI.$events.$off('fileUploadSuccess', this.fileUploadSuccess);
  }
}
</script>

<style lang="stylus">
.hhFileUpload
  padding-top: 8px
  padding-bottom: 2px
  color: #555
  font-size: 12px
  text-transform: none
  min-height: 30px

  .hhFileUploadInput
    display: flex;
    align-items: center;

    input[type="file"]
      font-size: 14px
      font-weight: bold
      line-height: normal

  &.isDisabled
    color: #999

    .file .file-link
      color: #999

  .file
    display: flex
    align-items: center
    padding-bottom: 6px
    font-size: 20px
    line-height: 26px
    font-weight: normal
    white-space: nowrap

    .file-spinner
      position: relative
      flex: none
      width: 20px
      height: 20px

    .file-progress
      flex: none
      margin-left: 6px
      border-radius: 6px

    .file-link
      color: #555
      padding: 0px 5px
      overflow: hidden
      text-overflow: ellipsis

    .file-size
      position: relative
      top: 2px
      color: #999
      font-size: 14px

    .file-delete
      position: relative
      top: 1px
      margin-left: 3px
      cursor: pointer

  .q-icon
    font-size: 20px

  .fileErrors
    padding-top: 5px
    color: rgb(233, 30, 99)

    > div
      padding-top: 5px
</style>
